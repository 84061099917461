import {React, useState, useEffect}from "react";
import mappedStations from "../../utilities/mappedStations";
import { useNavigate } from 'react-router-dom';
import Navbar from "../Navbar";
import axios from "axios";
import { useCookies } from "react-cookie";

const User = (props) => {

    const {user,  updateUsers, setUpdateUsers, version} = props;
    let navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const [editMode, setEditMode] = useState(false);
    const [station, setStation] = useState(user.calendar_stations);
    const [name, setName] = useState(user.name);
    const [isActive, setIsActive] = useState(user.isActive);
    const [email, setEmail] = useState(user.email)
    const [stationToAdd, setStationToAdd] = useState();
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [editPassword, setEditPassword] = useState(false);
    const [passwordToSet, setPasswordToSet] = useState()
    const [allStations, setAllStations] = useState();
    
    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-stations?isDeleted_eq=false'
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.data[0]){
                setAllStations(response.data);
            }
        })
    },[])

    const handleEditPassword = () => {
        if(editPassword){
            if(password && password.length>=8){
                if(password === passwordCheck){
                    setPasswordToSet(password)
                    setEditPassword(!editPassword)
                }
                else{
                    alert("Hasło i potwierdzenie hasła nie są identyczne.")
                }
            }
            else{
                alert("Hasło musi mieć co najmniej 8 znaków.")
            }
            setPassword();
            setPasswordCheck();
        }
        else{
            setEditPassword(!editPassword)
        }
    }
    const handleEdit = () => {
        if(editMode){
            if(editedUser.name, editedUser.email){
                fetch('https://'+ version + '.soft99.eu/calendar-users/' + user.id, {
                    method: "PUT",
                    mode: 'cors',
                    headers: {
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${cookies.JWTToken}`, 
                    },
                    body: JSON.stringify(editedUser)
                }
              ).then((response)=>{
                  if(response.status===200){
                    setUpdateUsers(updateUsers+1)
                    setEditMode(!editMode);
                  }
                  else{
                      alert("Coś poszło nie tak, sprawdź poprawność danych.")
                  }
              })
            }
            else{
                alert("Wypełnij wymagane pola (*).")
            }
        }
        else{
            setEditMode(!editMode);
        }
    };
    const editedUser = {
        name : name,
        email : email,
        password : passwordToSet && passwordToSet,
        calendar_stations : station,
        isActive : isActive,
        role: user.role
    }
    const handleRemoveUser = () => {
        if(window.confirm("Czy na pewno chcesz usunąć tego użytkownika?")){
        fetch('https://'+ version + '.soft99.eu/calendar-users/' + user.id, {
            method: "PUT",
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': `Bearer ` + cookies.JWTToken, 
            },
            body: JSON.stringify({
                isDeleted : true,
            })
        }
            )
              .then(() =>{
                setUpdateUsers(updateUsers+1)
                navigate('/MenageUsers')
            }
          )
        }
    }
    const handleAddStation = (value) => {
        if(value.id >= 0){
            console.log(value)
            if(!station.includes(value)){
                station.push(value)
                setStation([...station])
            }
            else{
                alert("Pracownik jest już przypisany do tego stanowiska.")
            }
        }
    }
    const handleRemoveStation = (value) => {
        setStation(station.filter((singleStation)=>{
            return singleStation !== value
        }));
    }

    const listedUserStations = station.map((station)=>{
        return <li key={station.id}>
            {station.name}
            {editMode && <button onClick={()=>{handleRemoveStation(station)}}>Usuń</button>}
        </li>
    })
    return (
        <div className="container">
            <Navbar />
            <div className="user">
                    {editMode ? 
                    <div>
                        <label for="name">imię i nazwisko *</label>
                        <input
                            className="form-control"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}>
                        </input>
                        <label for="email">email *</label>
                        <input
                            className="form-control"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}>
                        </input>
                        {user.role === "operator" && 
                            <div>
                                <select
                                    onChange={(e)=>{setStationToAdd(
                                        allStations.filter((s=>s.id === parseInt(e.target.value)))[0]
                                        )}}>
                                    <option value="all">stanowiska</option>
                                    {mappedStations(allStations)}
                                </select>
                                <button onClick={()=>handleAddStation(stationToAdd)}>Dodaj</button>
                                <ul>
                                    {listedUserStations}
                                </ul>
                            </div>}
                    </div> :
                    <div>
                        <h3>{user.name}</h3>
                        <p>{user.email}</p>
                            {user.role === "operator" && 
                        <div>
                            <h5>{user.role}: </h5>
                            <ul>
                                {listedUserStations}
                            </ul>
                        </div>}
                    </div>}
                    <p className={editMode && "user-active"} onClick={()=>{editMode && setIsActive(!isActive)}}>{isActive ? "aktywny" : "nieaktywny"}</p>
                    {editMode && <div className="edit-password">
                        {editPassword && <div>
                            
                            <label for="new-password">nowe hasło</label>
                            <input
                                className="form-control"
                                name="new-password"
                                type="password"
                                onChange={(e)=>{setPassword(e.target.value)}}>
                            </input>
                            <label for="confirm-password">powtórz hasło</label>
                            <input
                                className="form-control"
                                name="confirm-password"
                                type="password"
                                onChange={(e)=>{setPasswordCheck(e.target.value)}}>
                            </input>
                        </div>}
                        <div>
                            {editPassword && <button className="btn btn-outline-secondary" onClick={()=>{setEditPassword(false)}}>Anuluj</button>}
                            <button className="btn btn-outline-secondary" onClick={()=>handleEditPassword()}>{editPassword ? "Zapisz hasło" : "Edytuj hasło"}</button>
                        </div>
                    </div>}
                    
                    {editMode && <button className="btn btn-outline-secondary" onClick={()=>{setEditMode(!editMode)}}>Anuluj</button>}
                    <button type="button" className="btn btn-outline-secondary" onClick={()=>{handleEdit()}}>{editMode ? "Zapisz" : "Edytuj"}</button>
                    {user.id !== cookies.user.id && <button className="btn btn-outline-danger" onClick={()=>{handleRemoveUser()}}>Usuń</button>}
            </div>
        </div>
    );
};

export default User;
