import { useState } from "react";
import moment from "moment";
import SelectClient from "./SelectClient";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import AddVehiclePhotos from "./AddVehiclePhotos";

const VehicleInspectionCard = (props) => {

    const {passedEvent, version, setUpdateEvents, updateEvents, allUsers, setUpdateClients, updateClients} = props;
    const [cookies, setCookie] = useCookies();
    let navigate = useNavigate()
    const VIC = passedEvent && passedEvent.calendar_inspection_card
    const [client, setClient] = useState(passedEvent && passedEvent.calendar_client)
    const [vehicle, setVehicle] = useState(passedEvent && passedEvent.calendar_vehicle)
    const [dateTime, setDateTime] = useState(moment(new Date().toISOString()).format().slice(0, -9))
    const [soiling, setSoiling] = useState()
    const [services, setServices] = useState()
    const [comment, setComment] = useState()
    const [files, setFiles] = useState([]);
    const [alternativeDestination, setAlternativeDestination] = useState()
    const [transportPrice, setTransportPrice] = useState()
    const [transportConfirmation, setTransportConfirmation] = useState(false)
    const [drivingCharacteristicsConfirmation, setDrivingCharacteristicsConfirmation] = useState(false)
    const newVIC = {
        "calendar_event" : passedEvent.id,
        "date" : dateTime + ":00.000Z",
        "soiling" : soiling,
        "services" : services,
        "comment" : comment,
        "deliveryAddress" : alternativeDestination,
        "deliveryPrice" : transportPrice,
        "deliveryConfirmation" : transportConfirmation,
        "isDrivigCheckNeeded" : drivingCharacteristicsConfirmation,
        "createdBy" : cookies.user.id
    }

    const saveVIC = () => {
        if(dateTime && soiling){
            if(soiling !== "blocking"){
                if(window.confirm("Karta Inspekcji Pojazdu zawiera zgody wyrażone przez klienta, w związku z czym nie można jej później edytować. Czy chcesz zapisać Kartę?")){
                    axios.get('https://'+ version + '.soft99.eu/calendar-events/'+passedEvent.id,
                    {
                        headers: { Authorization: `Bearer ` + cookies.JWTToken }
                    })
                    .then(response=>{
                        if(response.data){
                            if(!response.data.calendar_inspection_card){
                                fetch('https://'+ version + '.soft99.eu/calendar-inspection-cards', {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        'Accept':'application/json',
                                        'Content-Type':'application/json',
                                        'Authorization': `Bearer ` + cookies.JWTToken, 
                                    },
                                    body: JSON.stringify(newVIC)
                                })
                                .then(response=>{
                                    if(response.ok){
                                        alert("Pomyślnie dodano Kartę Inspekcji Pojazdu.")
                                        setUpdateEvents(updateEvents+1)
                                        navigate('/Event'+passedEvent.id)
                                    }
                                    else{
                                        alert("Coś poszło nie tak... Sprawdź poprawność wszystkich danych i spróbuj ponownie.")
                                    }
                                })
                            }
                            else{
                                alert("Do tego wydarzenia została już przypisana Karta Inspekcji Pojazdu.")
                            }
                        }
                        else{
                            alert("Nie udało się wczytać danych na temat wizyty. Sprawdź, czy nie została usunięta i spróbuj jeszcze raz.")
                        }
                    })
                }
            }
            else{
                alert("Zabrudzenia pojazdu nie pozwalają na jego inspekcję.")
            }
        }
        else{
            alert("Uzupełnij wszystkie wymagane pola.")
        }
    }
    return(
        <div className="vehicle-inspection-card container">
            <Navbar />
            <div className="row">
                <div className="col-sm-4">
                    <h1>KARTA INSPEKCJI POJAZDU</h1>
                </div>
            </div>
            <div className="row">
                <SelectClient version={version} editMode={false} client={client} setClient={setClient} vehicle={vehicle} setVehicle={setVehicle} setUpdateClients={setUpdateClients} updateClients={updateClients}/>
            </div>
            {VIC ? 
            //===========ISTNIEJĄCA KIP===============
            <div>
                <div className="row">
                    <div className="col-sm-6">
                     </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <b><label 
                                htmlFor="soiling">Zabrudzenia na pojeździe:
                        </label></b>
                        {VIC.soiling === "none" && <p>brak</p>}
                        {VIC.soiling === "light" && <p>lekkie</p>}
                        {VIC.soiling === "hard" && <p>ciężkie</p>}
                        {VIC.soiling === "blocking" && <p>uniemożliwiające inspekcję pojazdu</p>}
                        <b><label 
                            htmlFor="comment">Uwagi:
                        </label></b>
                        <p name="comment">
                            {VIC.comment}
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <AddVehiclePhotos VIC={VIC} files={files} setFiles={setFiles}/>
                        {/* <b><label 
                            htmlFor="services">Zakres czynności:
                        </label></b>
                        <p name="services">
                            {VIC.comment}
                        </p> */}
                    </div>
                </div>
                <div className="row">
                    <h5>Zgody</h5>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-check">
                            <div>
                                <input
                                    disabled
                                    checked={VIC.deliveryConfirmation}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox1">
                                </input>
                                <b><label 
                                    className="form-check-label"
                                    htmlFor="checkbox1">Transport pojazdu klienta</label></b>
                            </div>
                            <label 
                                    className="form-check-label"
                                    htmlFor="transport-price">Koszt transportu:</label>
                            <p name="transport-price">{VIC.transportPrice ? VIC.transportPrice : "Nie podano"}</p>
                            <p className="small">
                                Wyrażam zgodę na prowadzenie pojazdu przez pracownika/współpracownika
                                Soft99® Garage do punktu lub z punktu oraz do lub z mojej siedziby lub innego
                                wskazanego przeze mnie miejsca: 
                            </p>
                            <p name="delivery-address">{VIC.deliveryAddress ? VIC.deliveryAddress : "Nie podano"}</p>
                        </div>
                        <div className="form-check">
                            <input
                                disabled
                                checked={VIC.isDrivigCheckNeeded}
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox2">
                            </input>
                            <b><label htmlFor="checkbox2">Wykonanie kontroli właściwości jezdnych</label></b>
                                <p className="small">
                                    W tym celu powierzam pojazd i przyjmuję do wiadomości, ze transport oraz kontrola
                                    pojazdu odbywają się zgodnie z zasadami określonymi w Regulaminie Soft99®
                                    Garage, co niniejszym akceptuję bez zastrzeżeń.</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <p><b>
                            Wycena usługi wykonywana jest przez naszego eksperta, na
                            podstawie otrzymanego od klienta zlecenia oraz wstępnej
                            inspekcji pozostawionego pojazdu. 
                        </b></p>
                        <p className="small">
                            Zlecenie wykonania usługi jest jednoznaczne z akceptacją Regulaminu Soft99®
                            Garage dostępnego w punkcie oraz na stronie internetowej soft99garage.pl.
                            Potwierdzam zapoznanie sie z treścią Regulaminu Soft99® Garage. Rozumiem
                            i akceptuję jego treść bez zastrzeżeń.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <b><label 
                        htmlFor="date">KIP sporządzono:
                    </label></b>
                    <p>{new Date(VIC.date).toString().substring(0, 21)} {VIC.createdBy && "przez " + allUsers.find(user=>user.id === VIC.createdBy).name}</p>
                </div>
            </div> :
            //===========NOWA KIP===============
            <form>
                <div className="row">
                    <div className="col-sm-6">
                        <b><label 
                            htmlFor="date">KIP sporządzono:
                        </label></b>
                        <input 
                            name="date"
                            value={dateTime}
                            onChange={(e)=>{setDateTime(e.target.value)}}
                            className="form-control"
                            type="datetime-local">
                        </input>
                     </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <b><label 
                            htmlFor="soiling">Zabrudzenia na pojeździe:
                        </label></b>
                        <select
                            name="soiling"
                            onChange={(e)=>setSoiling(e.target.value)}
                            className="form-select">
                            <option>wybierz</option>
                            <option
                                value="light">lekkie
                            </option>
                            <option
                                value="hard">ciężkie
                            </option>
                            <option
                                value="blocking">uniemożliwiające inspekcję pojazdu
                            </option>
                        </select>
                        <textarea 
                            onChange={(e)=>setComment(e.target.value)}
                            value={comment}
                            rows="6"
                            className="form-control"
                            placeholder="uwagi">
                        </textarea>
                    </div>
                    <div className="col-sm-6">
                        <AddVehiclePhotos VIC={VIC} files={files} setFiles={setFiles}/>
                        {/* <textarea 
                            onChange={(e)=>setServices(e.target.value)}
                            value={services}
                            rows="6"
                            className="form-control"
                            placeholder="zakres czynności">
                        </textarea> */}
                    </div>
                </div>
                <div className="row">
                    <h5>Zgody</h5>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-check">
                            <div>
                                <input
                                    onChange={()=>setTransportConfirmation(!transportConfirmation)}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox3">
                                </input>
                                <b><label 
                                    className="form-check-label"
                                    htmlFor="checkbox4">Transport pojazdu klienta</label></b>
                            </div>
                            <input 
                                onChange={(e)=>{setTransportPrice(e.target.value)}}
                                value={transportPrice}
                                id="price"
                                className="form-control"
                                type="number"
                                placeholder="koszt">
                            </input>
                            <p className="small">
                                Wyrażam zgodę na prowadzenie pojazdu przez pracownika/współpracownika
                                Soft99® Garage do punktu lub z punktu oraz do lub z mojej siedziby lub innego
                                wskazanego przeze mnie miejsca: 
                                <input 
                                    id="alt-destination"
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={alternativeDestination}
                                    onChange={(e)=>{setAlternativeDestination(e.target.value)}}></input>
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                onChange={()=>{setDrivingCharacteristicsConfirmation(!drivingCharacteristicsConfirmation)}}
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox4">
                             </input>
                            <b><label htmlFor="checkbox4">Wykonanie kontroli właściwości jezdnych</label></b>
                                <p className="small">
                                    W tym celu powierzam pojazd i przyjmuję do wiadomości, ze transport oraz kontrola
                                    pojazdu odbywają się zgodnie z zasadami określonymi w Regulaminie Soft99®
                                    Garage, co niniejszym akceptuję bez zastrzeżeń.</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <p><b>
                                Wycena usługi wykonywana jest przez naszego eksperta, na
                                podstawie otrzymanego od klienta zlecenia oraz wstępnej
                                inspekcji pozostawionego pojazdu. 
                            </b></p>
                            <p className="small">
                                Zlecenie wykonania usługi jest jednoznaczne z akceptacją Regulaminu Soft99®
                                Garage dostępnego w punkcie oraz na stronie internetowej soft99garage.pl.
                                Potwierdzam zapoznanie sie z treścią Regulaminu Soft99® Garage. Rozumiem
                                i akceptuję jego treść bez zastrzeżeń.
                            </p>
                        </div>
                    </div>
                <button 
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={()=>{saveVIC()}}>
                    Zatwierdź
                </button>
            </form>}
        </div>
    )
}

export default VehicleInspectionCard;
