const mappedOperators = (operators, station)=>{
    let result = [];
    if(station){
        operators = operators.filter(singleOperator => {
            let result
             singleOperator.calendar_stations.map((singleStation)=>{
              if(singleStation.id === station.id) {
                result = singleStation
              }
            })
            return singleOperator.calendar_stations.includes(result)
          });
    }
    if(operators){
        operators.map(singleOperator => {
            result.push( <option key={singleOperator.id} className={singleOperator.isActive ? "mapped-operator-active" : "mapped-operator-not-active"} value={singleOperator.id}>{singleOperator.name}</option>);
        });
    }
    else{
        result.push(null);
    }
    return result;
}

export default mappedOperators;
