import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";

const ManagersView = () => {
    let navigate = useNavigate();
    
    return(
        <div className="container">
            <Navbar />
            <div>
                <button 
                    className="btn btn-info btn-lg" 
                    onClick={()=>{
                    navigate('/MainCalendar');
                }}>Główny kalendarz</button>
            </div>
            <div>
                <button  
                    className="btn btn-info btn-lg"  
                    onClick={()=>{
                    navigate('/EventsHistory');
                }}>Historia wizyt</button>
            </div>
            {/* <div>
                <button  className="btn btn-info btn-lg"  onClick={()=>{
                    navigate('/Stations');
                }}>Stanowiska</button>
            </div> */}
        </div>
    );
}

export default ManagersView;