import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import bin from '../style/images/bin.png';

const AddVehiclePhotos = (props) => {
    const {VIC, files, setFiles} = props
    const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];
    const mappedFiles = files.map(file=>{
        return <p className="mapped-file" key={file.name}>
            {file.name}
            <img onClick={()=>{
                setFiles(files.filter(singleFile=>singleFile != file))
                }} src={bin}/>
        </p>
    })
    return(
        <div>
            {!VIC ? <div>
                <b><label 
                    htmlFor="soiling">Zdjęcia:
                </label></b>
                <FileUploader
                    multiple={true}
                    classes="drop-area"
                    handleChange={(file)=>{
                        files.push(file[0])
                        setFiles([...files])}}
                    name="file"
                    label="Przeciągnij zdjęcia pojazdu, lub kliknij by przeglądać zawartość dysku"
                    types={fileTypes}
                    onTypeError={()=>{alert(`Niewłaściwy typ pliku. Akceptowane typy: ${fileTypes}`)}}
                />
                {mappedFiles}
            </div>:
            <div>
                {mappedFiles}
            </div>}
        </div>
    )
}

export default AddVehiclePhotos