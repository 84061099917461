import { useNavigate, } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useCookies } from 'react-cookie';
import plus from '../style/images/plus.png';
import bin from '../style/images/bin.png';
import axios from 'axios';

const Stations = (props) => {
    
    const {allStations, setAllStations, updateStations, setUpdateStations, version} = props
    const [allUsers, setAllUsers] = useState();
    let managersAndAdmins = allUsers && allUsers.filter((user)=>{return user.role !== "operator"})
    const [editMode, setEditMode] = useState(false);
    let navigate = useNavigate();
    const [newStationName, setNewStationName] = useState();
    const [cookies, setCookie] = useCookies();
    const stationsToShow = cookies.user.role === "operator" ? 
        allStations && allStations.filter((station)=>{
            let result
            cookies.user.calendar_stations.map((singleStation)=>{
              if(singleStation.id === station.id) {
                result = singleStation
              }
            })
            return cookies.user.calendar_stations.includes(result)
    }) : allStations;

    const [updateUser, setUpdateUser] = useState(0);

    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-users?isDeleted_eq=false'
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            setAllUsers(response.data)
        })
    },[])
    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-stations?isDeleted_eq=false'
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
            setAllStations(response.data);
        }
        })
    },[updateStations])

    useEffect(()=>{
        managersAndAdmins && managersAndAdmins.forEach((user)=>{
            fetch('https://'+ version + '.soft99.eu/calendar-users/' + user.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ${cookies.JWTToken}`, 
                },
                body: JSON.stringify({
                    calendar_stations : allStations,
                })
            }
          ).then(()=>{
            setUpdateUser(updateUser+1)
          })
       })
    },[allStations])

    const isStationUnique = () => {
        let result = true
        for(let i=0; i<allStations.length; i++){
            if(allStations[i].name === newStationName){
                
                console.log(allStations[i].name)
                console.log(newStationName)
                result = false
            }
        }
        return result;
    }
    const handleRemoveStation = (station) => {
        
        if(window.confirm("Czy na pewno chcesz usunąć to stanowisko?")){
        fetch('https://'+ version + '.soft99.eu/calendar-stations/' + station.id, {
            method: "PUT",
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': `Bearer ` + cookies.JWTToken, 
            },
            body: JSON.stringify({
                isDeleted : true,
            })
        }
            ).then((response)=>{
                if(response.status === 200){
                    setUpdateStations(updateStations+1)
                }
              })
        }
    }

    const handleAddStation = ()=> {
        if(newStationName){
            if(isStationUnique()){
                fetch('https://'+ version + '.soft99.eu/calendar-stations', {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${cookies.JWTToken}`, 
                    },
                    body: JSON.stringify({
                        name : newStationName,
                        isDeleted: false
                    })
                }
              )
                .then(() => {
                    setUpdateStations(updateStations+1)
                })
                .catch((error) =>
                    console.log(error)
                )
                setEditMode(!editMode);
            }
            else{
                alert("Takie stanowisko już istnieje.")
            }
        }
        else{
            alert("Uzupełnij nazwę stanowiska.")
        }
    }
    const mappedStations = stationsToShow && stationsToShow.map((station)=>{
        return(
            <div key={station.id}
                className='station-nav'>
                <h1
                 onClick={()=>{
                    navigate('/'+station.name);
                    }}>{station.name}</h1>
                {/* {cookies.user.role !== "operator" &&
                <div 
                    className='img-container'
                    onClick={()=>handleRemoveStation(station)}>
                    <img 
                        src={bin}/>
                </div>} */}
            </div>
        )
    })

    return(
        <div className='stations-wrapper'>
            <Navbar />
            <h1>Stanowiska</h1>
            <div className='stations container row'>
                {mappedStations}
                {/* {cookies.user.role === 'admin' && <div
                    className='station-add'>
                        {editMode ? <div className='station-add-content'>
                            <input 
                                placeholder='Nazwa'
                                type='text'
                                value={newStationName}
                                onChange={(e)=>setNewStationName(e.target.value)}></input>
                            <button
                                onClick={()=>{
                                    handleAddStation();
                                }}>Ok</button>
                            <button
                                onClick={()=>{
                                    setEditMode(false);
                                }}>Anuluj</button>
                        </div>
                        :
                        <div 
                            className='station-add-content'
                            onClick={()=>{
                                setEditMode(true);
                            }}>
                            <img 
                            src={plus}
                            />
                        </div>
                        }
                </div>} */}
            </div>
        </div>
    )
}
export default Stations;
