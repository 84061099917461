import {React, useState, useEffect} from "react";
import mappedOperators from "../utilities/mappedOperators";
import Navbar from "./Navbar";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Shift = (props) => {
    const {allShifts, setAllShifts, passedShift, version} = props;
    let navigate = useNavigate();
    
    const [shift, setShift] = useState(passedShift)
    const [cookies, setCookie] = useCookies();
    const [editMode, setEditMode] = useState(false)
    const [allOperators, setAllOperators] = useState()
    const [operatorId, setOperatorId] = useState(shift.calendar_user.id)
    const [start, setStart] = useState(shift.start)
    const [end, setEnd] = useState(shift.end)
    const [updateShift, setUpdateShift] = useState(0)
    const [operator, setOperator] = useState(shift.calendar_user)

    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-users/?role_eq=operator&isDeleted_eq=false'
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            setAllOperators(response.data)
        })
      },[])

      useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-shifts/' + shift.id,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            response.data.start = new Date(response.data.start)
            response.data.end = new Date(response.data.end)
            setShift(response.data)
        })
      },[updateShift])
      
    const editedShift = {
        title: operator.name,
        type: "shift",
        start: new Date(start),
        end: new Date(end),
        calendar_station: shift.calendar_station,
        calendar_user: operator
      };

    const handleEditSaveButton = () => {
        if(editMode){
            editedShift.start = new Date(editedShift.start);
            editedShift.start = editedShift.start.toISOString();
            editedShift.end = new Date(editedShift.end);
            editedShift.end = editedShift.end.toISOString();
            fetch('https://'+ version + '.soft99.eu/calendar-shifts/' + shift.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken, 
                },
                body: JSON.stringify(editedShift)
            }
                )
                .then((response)=>{
                    if(response.status===200){
                        editedShift.start = new Date(editedShift.start);
                        editedShift.end =  new Date(editedShift.end);
                        setUpdateShift(updateShift+1)
                    }
                    else{
                        console.log(response)
                    }
                }
                    )
            const editedSchedule = [...allShifts]
            setAllShifts(editedSchedule)
        }
        setEditMode(!editMode)
    }
    const handleRemoveShift = () => {
        if(window.confirm("Czy na pewno chcesz usunąć tę zmianę?")){
            fetch('https://'+ version + '.soft99.eu/calendar-shifts/' + shift.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken, 
                },
                body: JSON.stringify({
                    isDeleted : true,
                })
            }
                )
              .then(() =>{
                navigate('/MainCalendar')
            }
          )
        }

    }
    return (
        <div>
            <Navbar />
            {<div className="event">
            <p><b>Operator: </b>
                {editMode ? 
                    <select 
                        value={operator.id}
                        onChange={(e)=>{
                            setOperator(allOperators.filter(singleOperator=>singleOperator.id===parseInt(e.target.value))[0])}}>
                        {mappedOperators(allOperators, shift.station)}
                    </select> : 
                    shift.title}</p>
            <p><b>Stanowisko: </b>{shift.calendar_station.name}</p>
            <p><b>Początek: </b>
                {editMode ? 
                    <input 
                        type="datetime-local" 
                        value={typeof start === "string" ? start : moment(start.toISOString()).format().slice(0, -9)}
                        onChange={(e)=>{setStart(e.target.value)}}></input> : 
                    shift.start.toString().substring(0, 21)}</p>
            <p><b>Koniec </b>
                {editMode ? 
                    <input 
                        type="datetime-local" 
                        value={typeof end === "string" ? end : moment(end.toISOString()).format().slice(0, -9)} 
                        onChange={(e)=>{setEnd(e.target.value)}}></input> : 
                        shift.end.toString().substring(0, 21)}</p>
                {cookies.user.role !== "operator" && <div>
                    <button className="btn btn-outline-danger" onClick={()=>{
                        handleRemoveShift()
                                }}>Usuń
                    </button>
                    <button className="btn btn-outline-secondary" onClick={()=>{handleEditSaveButton()
                                }}>{editMode ? "Zapisz" : "Edytuj"}
                    </button>
                </div>}
 
            </div>}
        </div>
    )
}

export default Shift;
