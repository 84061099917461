import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie";
import axios from "axios";
import Navbar from "./Navbar";
import EditVehicle from "./EditVehicle";

const Client = (props) => {
    const {passedClient, setClientsToRender, clientsToRender, version, setUpdateEvents, updateEvents, updateClients, setUpdateClients} = props;
    const [client, setClient] = useState(passedClient);
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(client.name);
    const [phone, setPhone] = useState(client.phone);
    const [email, setEmail] = useState(client.email);
    const [brand, setBrand] = useState()
    const [model, setModel] = useState()
    const [plateNumber, setPlateNumber] = useState()
    const [vin, setVin] = useState('11111111111111111')
    const [vehicleToEdit, setVehicleToEdit] = useState()
    const [editVehicleEditMode, setVehicleEditMode] = useState(false)

    let vehiclesIDs = []
    let vehicleIDsToAdd = []    

    const listedVehicles = client.calendar_vehicles && client.calendar_vehicles.map((singleVehicle)=>{
        vehiclesIDs.push(singleVehicle.id)
        return(
            <li key={singleVehicle.id} className={"listed-vehicle"}>
                <span>{singleVehicle.brand + " " + singleVehicle.model}</span>
                <span>{singleVehicle.plateNumber}</span>
                <span>{singleVehicle.vin}</span>
                {editMode && <button 
                    className="btn btn-outline-secondary"
                    onClick={()=>{
                        setVehicleEditMode(true)
                        setVehicleToEdit(singleVehicle)
                    }} 
                    type="button">Edytuj</button>}
            </li>
        )
    })
    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-clients/'+client.id,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        }).then(response=>{
            setClient(response.data)
            setCookie('Client', response.data, { path: '/' })
            clientsToRender.push(response.data)
            setClientsToRender([...clientsToRender])
            setIsLoading(false)
        })
    },[updateClients])
    const handleAddVehicle = (vehicle) =>{
        fetch('https://'+ version + '.soft99.eu/calendar-vehicles', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': `Bearer ` + cookies.JWTToken,
            },
            body: JSON.stringify(vehicle)
        }).then(response => response.json())
        .then(data =>{
            vehiclesIDs.push(data)
            fetch('https://'+ version + '.soft99.eu/calendar-clients/'+client.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify({
                    "calendar_vehicles": vehiclesIDs
                })
            })
            .then(()=>{
                setUpdateClients(updateClients+1)
                setBrand("")
                setModel("")
                setPlateNumber("")
                setVin("11111111111111111")
            })
        })
    }
    const handleSaveClient = () => {

        fetch('https://'+ version + '.soft99.eu/calendar-clients/'+client.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify({
                    "name": name,
                    "email": email,
                    "phone": phone
                })
            }).then(response=>{
                setEditMode(false)
                setUpdateClients(updateClients+1)
                setUpdateEvents(updateEvents+1)
            })
        }
    
    return(
        <div>{isLoading ? 
            <div></div> : 
            <div className="client container">
                {(vehicleToEdit && editVehicleEditMode) && <EditVehicle vehicleToEdit={vehicleToEdit} version={version} setVehicleEditMode={setVehicleEditMode}/>}
                <Navbar />
                {editMode ? <form>
                    <span><b>Nazwa:</b></span>
                    <input 
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        className="form-control" 
                        type={"text"}>
                    </input>
                    <span><b>Numer tel.:</b></span>
                    <input 
                        value={phone}
                        onChange={(e)=>{setPhone(e.target.value)}}
                        className="form-control"
                        type={"text"}>
                    </input>
                    <span><b>Email:</b></span>
                    <input 
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        className="form-control"
                        type={"text"}>
                    </input>
                    <span><b>Pojazdy:</b></span>
                    <ul>
                        {listedVehicles}
                        <li>
                            <input
                                className="form-control"
                                placeholder="marka"
                                value={brand}
                                type={"text"}
                                onChange={(e)=>{setBrand(e.target.value)}}></input>
                            <input
                                className="form-control"
                                placeholder="model"
                                value={model}
                                type={"text"}
                                onChange={(e)=>{setModel(e.target.value)}}></input>
                            <input
                                className="form-control"
                                placeholder="numer rejestracyjny"
                                value={plateNumber}
                                type={"text"}
                                onChange={(e)=>{setPlateNumber(e.target.value)}}></input>
                            <input
                                className="form-control"
                                placeholder="vin"
                                value={vin}
                                type={"text"}
                                onChange={(e)=>{setVin(e.target.value)}}></input>
                            <button 
                                className="btn btn-outline-secondary"
                                type={"button"}
                                onClick={()=>{
                                handleAddVehicle(
                                    {
                                        "brand": brand,
                                        "model": model,
                                        "plateNumber": plateNumber,
                                        "vin": vin
                                    }
                                )
                            }}>Dodaj pojazd</button>
                        </li>
                    </ul>
                </form> :
                <div>
                    <span><b>Nazwa:</b></span>
                    <div>
                        {client.name}
                    </div>
                    <span><b>Numer tel.:</b></span>
                    <div>
                        {client.phone}
                    </div>
                    <span><b>Email:</b></span>
                    <div>
                        {client.email}
                    </div>
                    <span><b>Pojazdy:</b></span>
                    <ul>
                        {listedVehicles}
                    </ul>
                </div>}
                {!editMode && 
                    <button
                        className="btn btn-outline-secondary" 
                        onClick={()=>{
                            setEditMode(true)}}>Edytuj klienta</button>}
                {editMode && 
                    <button
                        className="btn btn-outline-danger" 
                        onClick={()=>setEditMode(false)}>Anuluj</button>}
                {editMode && 
                    <button
                        className="btn btn-outline-primary" 
                        onClick={()=>handleSaveClient()}>Zapisz</button>}
            </div>}
        </div>

    )
}

export default Client
