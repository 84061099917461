import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    
    const [cookies, setCookie] = useCookies();
    let navigate = useNavigate();

    return (
        <nav className='navbar'>
                <div>
                    <p>Zalogowano jako: <b>{cookies.user.name}, {cookies.user.role}</b></p>
                </div>
                <div className='buttons'>
                        <button className='btn btn-outline-danger btn-lg m-2' onClick={()=>{
                            setCookie('user', null, { path: '/' });
                            navigate('/');
                            }}>
                            Wyloguj
                        </button>
                    {cookies.user.role === "operator" &&
                    <div>
                        <button className='btn btn-outline-dark btn-lg m-2' onClick={()=>{navigate('/Stations')}}>Stanowiska</button>
                    </div>}
                    {cookies.user.role === "manager" &&
                    <div>
                        <button className='btn btn-outline-dark btn-lg m-2' onClick={()=>{navigate('/ManagersView')}}>Menu</button>
                    </div>}
                    {cookies.user.role === "admin" &&
                    <div>
                        <button className='btn btn-outline-dark btn-lg m-2' onClick={()=>{navigate('/AdminsView')}}>Menu</button>
                    </div>}   

                </div> 
        </nav>
    )
}

export default Navbar;
