import {React, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../Navbar";
import axios from "axios";
import qs from 'qs'
import { useCookies } from 'react-cookie';

const MenageUsers = (props) => {

    const { updateUsers, setUpdateUsers, allUsers, setAllUsers, version} = props;
    const [cookies, setCookie] = useCookies();
    let navigate = useNavigate();
    const [name, setName] = useState();
    const [isActive, setIsActive] = useState(true);
    const [email, setEmail] = useState()
    const [password, setPassword] = useState();
    const [confirmedPassword, setConfirmedPassword] = useState();
    const [role, setRole] = useState("admin");
    const [showOperators, setShowOperators] = useState(true);
    const [showManagers, setShowManagers] = useState(true);
    const [showAdmins, setShowAdmins] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [allStations, setAllStations] = useState();
    const [usersToShow, setUsersToShow] = useState(allUsers);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(()=>{
        let roleQuery = []
        if(showAdmins){
            roleQuery.push({role: "admin"})
        }
        if(showManagers){
            roleQuery.push({role: "manager"})
        }
        if(showOperators){
            roleQuery.push({role: "operator"})
        }
        if(roleQuery.length===0){
            roleQuery.push({role: "none"})
        }
        const query = qs.stringify({
            _where: [ 
                {isDeleted: false},
                {_or: [
                    { name_contains: searchValue },
                    { email_contains: searchValue },
                ]},
                {_or: roleQuery},
              ],
        });
        axios.get(`https://${version}.soft99.eu/calendar-users?${query}`
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status === 200){
                setUsersToShow(response.data)
                setAllUsers(response.data)
                setIsLoading(false)
            }
        })
    },[updateUsers, searchValue, showAdmins, showManagers, showOperators])
    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-stations?isDeleted_eq=false'
        ,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setAllStations(response.data)
                setIsLoading(false)
            }
        })
    },[])

    const handleAddUser = (e) => {
        if(name && email){
            fetch('https://'+ version + '.soft99.eu/calendar-users', 
            {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${cookies.JWTToken}`, 
                    },
                    body: JSON.stringify({
                        name : name,
                        email : email,
                        password : password,
                        calendar_stations : role === "operator" ? [] : allStations,
                        isActive : isActive,
                        role: role,
                        isDeleted: false
                    })
                }
              )
                .then((response) => {
                    if(response.status === 400){
                        alert("Coś poszło nie tak. Sprawdź poprawność danych.")
                    }
                    setUpdateUsers(updateUsers+1)
                })
                .catch((error) =>{

                }
                )
        }
        else{
            alert("Uzupełnij wszystkie pola.");
        }
        e.preventDefault();
    }

    // useEffect(()=>{
    //     let filteredUsers = allUsers && allUsers.filter((user) => {
    //             return  user.name.toLowerCase().includes(searchValue.toLowerCase()) || 
    //                     user.email.toLowerCase().includes(searchValue.toLowerCase())
    //         });
    //     if(!showOperators){
    //         filteredUsers = filteredUsers.filter((user) => {
    //             return  user.role !== "operator";
    //         });
    //     }
    //     if(!showManagers){
    //         filteredUsers = filteredUsers.filter((user) => {
    //             return  user.role !== "manager";
    //         });
    //     }
    //     if(!showAdmins){
    //         filteredUsers = filteredUsers.filter((user) => {
    //             return  user.role !== "admin";
    //         });
    //     }
    //     setUsersToShow(filteredUsers);
    // },[searchValue, showOperators, showManagers, showAdmins, allUsers]);

    return ( 
        <div className="container">
            {(!isLoading && allUsers) && <div>
                <Navbar />
                <div className="menage-users">
                    <div className="row">
                        <div className="col">
                            <h1>Pracownicy</h1>
                        </div>
                        <div className="col">
                        </div>
                        <div className="col">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <form>
                                <input required className="form-control" type="text" value={name} placeholder="imię i nazwisko" onChange={(e)=>{setName(e.target.value)}}></input>
                                <input required autoComplete="new-password" className="form-control" type="email" value={email} placeholder="adres email" onChange={(e)=>{setEmail(e.target.value)}}></input>
                                <input required autoComplete="new-password" className="form-control" minLength="8" type="password" placeholder="hasło (min. 8 znaków)" onChange={(e)=>{setPassword(e.target.value)}}></input>
                                <input required className="form-control" minLength="8" type="password" placeholder="potwierdź hasło" onChange={(e)=>{setConfirmedPassword(e.target.value)}}></input>
                                <select className="form-select" onChange={(e)=>{setRole(e.target.value)}}>
                                    <option value="admin">admin</option>
                                    <option value="manager">manager</option>
                                    <option value="operator">operator</option>
                                </select>
                                <select className="form-select" onChange={(e) => {setIsActive(e.target.value)}}>
                                    <option value={true}>aktywny</option>
                                    <option value={false}>nieaktywny</option>
                                </select>
                                <button disabled={!password || password !== confirmedPassword || password.length<8} className="btn btn-outline-primary" onClick={(e)=>{handleAddUser(e)}}>Dodaj pracownika</button>
                            </form>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li className="search">
                                    <input
                                        onChange={(e)=>{
                                            setSearchValue(e.target.value)
                                        }}
                                        type="search"
                                        placeholder="szukaj">
                                    </input>
                                    <div>
                                        <input onChange={(e)=>{
                                            setShowAdmins(e.target.checked)
                                            }} name="admins" type="checkbox" checked={showAdmins}>
                                        </input>
                                        <label htmlFor="admins">admini</label>
                                    </div>
                                    <div>
                                        <input onChange={(e)=>{
                                            setShowManagers(e.target.checked)
                                            }} name="managers" type="checkbox" checked={showManagers}>
                                        </input>
                                        <label htmlFor="managers">managerowie</label>
                                    </div>
                                    <div>
                                        <input onChange={(e)=>{
                                            setShowOperators(e.target.checked)
                                            }} name="operators" type="checkbox" checked={showOperators}>
                                        </input>
                                        <label htmlFor="operators">operatorzy</label>
                                    </div>
                                </li>
                                {usersToShow.map(user =>{
                                    return (
                                        <li
                                            key={user.id}
                                            onClick={()=>{
                                            navigate('/User' + user.id)}}>
                                            <h3>{user.name}</h3>
                                            <h5>{user.role}</h5>
                                            <p>{user.email}</p>
                                            <p>{user.isActive ? "aktywny" : "nieaktywny"}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>}    
        </div>
    );
}

export default MenageUsers;
