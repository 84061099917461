import {React, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";

const LogIn = (props) => {

    const {version} = props
    let navigate = useNavigate();
    const [remindPassword, setRemindPassword] = useState(false);
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [cookies, setCookie] = useCookies();

    const handleGetJWTToken = () => {
        let body 
        if(version==="test.cms"){
            body = {
                identifier: "api",
                password: process.env.REACT_APP_TEST_API_KEY + "$"
            }
        }
        else if(version==="cms"){
            body = {
                    identifier: "api",
                    password: process.env.REACT_APP_PROD_API_KEY + "$"
                }
        }
        axios.post('https://'+ version + '.soft99.eu/auth/local',
            body
            ).then(response=>{
                setCookie('JWTToken', response.data.jwt, { path: '/' })
            })
            .catch(e=>{
            })
    }
    useEffect(()=>{
        if(!cookies.JWTToken){
            handleGetJWTToken();
        }
    },[])
    const handleLogIn = (e) => {
        e.preventDefault()
        axios.get('https://'+ version + '.soft99.eu/calendar-users/?email_eq=' + login + '&password_eq=' + password,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.data[0]){
                setCookie('user', response.data[0], { path: '/' });
                switch(response.data[0].role){
                    case "operator": navigate('/Stations')
                        break;
                    case "manager": navigate('/ManagersView')
                        break;
                    case "admin": navigate('/AdminsView')
                        break;
                }
            }
            else{
                alert("Login lub hasło jest niepoprawne.")
            }
        })
        .catch(e=>{
            console.log(e.response.status)
        })
    }
    const handleRemindPassword = () => {
        alert("Wysłano maila z przypomnieniem hasła, proszę odbierz pocztę.")
    }
    return( 
        <div className="container-sm d-flex justify-content-center login">
            <div className="row align-items-center">
                <div className="col">
                    <h1>Logowanie</h1>
                    {!remindPassword ? <form>
                        <input 
                            className="form-control"
                            type="email" 
                            placeholder="login (email)"
                            onChange={(e)=>{setLogin(e.target.value)}}></input>
                        <input 
                            className="form-control"
                            type="password"
                            placeholder="hasło"
                            onChange={(e)=>{setPassword(e.target.value)}}></input>
                        {/* <button className="btn btn-outline-secondary" type="button" onClick={()=>{setRemindPassword(true)}}>Przypomnij hasło</button> */}
                        <button className="btn btn-outline-primary" onClick={(e)=>{handleLogIn(e)}}>Zaloguj</button>
                    </form> :
                    <form>
                        <p className="form-text">Podaj email powiązany z Twoim kontem w celu przypomnienia hasła.</p>
                        <input 
                            value={login}
                            type="email"
                            placeholder="login (email)"
                            onChange={(e)=>{setLogin(e.target.value)}}></input>
                        <button className="btn btn-outline-secondary" type="button" onClick={()=>{setRemindPassword(false)}}>Powrót</button>
                        <button className="btn btn-outline-primary" type="submit" disabled={!login} onSubmit={()=>{handleRemindPassword()}}>Dalej</button>
                    </form>}
                </div>
            </div>
        </div>
    )
}

export default LogIn;