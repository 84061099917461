    const eventPropGetter =
      (event) => ({
        ...(event.calendar_station.name.includes("Myjnia1") && {
          style: {
            backgroundColor: 'rgba(20, 100, 150, 1)',
          },
        }),
        ...(event.calendar_station.name.includes("Myjnia2") && {
          style: {
            backgroundColor: 'rgba(0, 190, 240, 1)',
          },
        }),
        ...(event.calendar_station.name.includes("Myjnia3") && {
          style: {
            backgroundColor: 'rgba(50, 100, 230, 1)',
          },
        }),
        ...(event.calendar_station.name.includes("SKP") && {
            style: {
              backgroundColor: 'rgba(30, 157, 70, 1)',
            },
          }),
        ...(event.calendar_station.name.includes("Detailing") && {
            style: {
              backgroundColor: 'rgba(167, 50, 70, 1)',
            },
        }),    
        ...(event.calendar_station.name.includes("Opony") && {
            style: {
              backgroundColor: 'rgba(202, 195, 0, 1)',
            },
          }),      
        
      })
export default eventPropGetter;
