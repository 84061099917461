import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

const AddNewService = (props) => {
    const {version, setShowAddServicePopup} = props
    const [cookies, setCookie] = useCookies();
    const [name, setName] = useState()
    const [stations, setStations] = useState()
    const [station, setStation] = useState()
    const newService = {
        "name": name,
        "calendar_station": station
    }
    useEffect(()=>{
        axios.get(`https://${version}.soft99.eu/calendar-stations/?isDeleted_eq=false`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setStations(response.data);
            }
        })
    },[])
    const saveService = (e) => {
        if(name, station){
            fetch('https://'+ version + '.soft99.eu/calendar-services', {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify(newService)
            })
        }
        else{
            e.preventDefault();
            alert("Wszystkie pola muszą być uzupełnione.")
        }
    }
    const mappedStations = stations && stations.map((singleStation)=>{
        return(
            <option 
                key={singleStation.id}
                value={JSON.stringify(singleStation)}>
                {singleStation.name}
            </option>
        )
    })
    return(
        <div className="edit-service container">
            <form className="content">
                <h4>Wprowadź dane nowej usługi:</h4>
                <input 
                    placeholder="nazwa"
                    maxlength="40"
                    className="form-control"
                    value={name}
                    onChange={(e)=>setName(e.target.value)}></input>
                <select
                    className="form-select"
                    onChange={(e)=>{
                        if(e.target.value==="none"){
                            setStation()
                        }
                        else{
                            setStation(JSON.parse(e.target.value).id)
                        }
                    }}>
                    <option value={"none"}>Wybierz stanowisko</option>
                    {mappedStations}
                </select>
                <div className="edit-service-buttons">
                    <button
                        className="btn btn-danger"
                        onClick={()=>setShowAddServicePopup(false)}>Anuluj</button>
                    <button
                        className="btn btn-success"
                        onClick={(e)=>saveService(e)}>Zapisz</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewService