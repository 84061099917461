import { React, useState} from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import isAtFreeTimeSlot from "../utilities/isOnFreeTimeSlot";
import SelectClient from "./SelectClient";
import Services from "./Services"
import mappedStations from "../utilities/mappedStations";

const AddNewEvent = (props) => {
    const {passedStart, passedEnd, setShowNewEventPopup, stations, updateEvents, setUpdateEvents, setUpdateClients, updateClients, version} = props;
    const [cookies, setCookie] = useCookies();
    const [start, setStart] = useState(passedStart)
    const [end, setEnd] = useState(passedEnd)
    const [client, setClient] = useState();
    const [vehicle, setVehicle] = useState();
    const [description, setDescription] = useState();
    const [addedStationId, setAddedStationId] = useState();
    const [services, setServices] = useState([]);
    const clientName = client && client.name;
    const phone = client && client.phone;
    const email = client && client.email;
    let startOfWeek = moment(moment(new Date(passedStart)).startOf('week').toDate()).format().slice(0, 10)
    let endOfWeek = moment(moment(passedStart).endOf('week').format()).add(1, 'days').format().slice(0, 10)

    const station = () => {
        if(stations.length === 1){
            return stations[0];
        }
        else {
            for(let i=0; i<stations.length; i++){
                if(stations[i].id === addedStationId){
                    return stations[i];
                }
            }
        }
    }
    const handleAddEvent = (e) => {
        e.preventDefault();
        axios.get('https://'+ version + '.soft99.eu/calendar-events/'+
        '?_where[0][start_gte]='+ startOfWeek + 
        '&_where[0][end_lte]='+ endOfWeek + 
        '&isDeleted=false&_limit=-1',
            {
                headers: { Authorization: `Bearer ` + cookies.JWTToken }
            })
            .then(response=>{
                if(clientName && 
                    // services && 
                    phone && 
                    email && 
                    // services[0] &&
                    newEvent.calendar_station &&
                    newEvent.createdBy){
                    if( vehicle.plateNumber && 
                        vehicle.vin && 
                        vehicle.brand && 
                        vehicle.model){
                            if(isAtFreeTimeSlot(newEvent, response.data, cookies)){
                                fetch('https://'+ version + '.soft99.eu/calendar-events', {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        'Accept':'application/json',
                                        'Content-Type':'application/json',
                                        'Authorization': `Bearer ` + cookies.JWTToken, 
                                    },
                                    body: JSON.stringify(newEvent)
                                })
                                .then((response)=>{
                                    if(response.status===200){
                                        axios.get('https://'+ version + '.soft99.eu/calendar-events/'+
                                        '?_where[0][start_gte]='+ startOfWeek + 
                                        '&_where[0][end_lte]='+ endOfWeek + 
                                        '&isDeleted=false&_limit=-1',
                                        {
                                            headers: { Authorization: `Bearer ` + cookies.JWTToken }
                                        }).then((response)=>{
                                            
                                            let data = response.data[response.data.length-1]
                                            data.start = new Date(data.start);
                                            data.end = new Date(data.end);
                                            setUpdateClients(updateClients+1)
                                            setUpdateEvents(updateEvents+1)
                                        })
                                        setShowNewEventPopup(false)
                                    }
                                    else{
                                        alert("Coś poszło nie tak, sprawdź poprawność danych.")
                                    }
                                })
                        }
                    }
                    else{
                        alert("Uzupełnij dane samochodu (model, marka, numer VIN i numer rejestracyjny) klikając 'Edytuj dane klienta'.")
                    }
                }
                else{
                    alert("Uzupełnij wymagane pola (*).")
                }    
            })
    }
    const newEvent = 
    {
      title: clientName,
      start: start,
      end: end,
      isDeleted: false,
      calendar_client: client && client.id,
      calendar_vehicle: vehicle && vehicle.id,
      calendar_station : station(),
      calendar_services: services,
      createdBy: cookies.user.id,
      updatedBy: cookies.user.id,
      resources : {
        description: description,
        rateDescription : "",
        rate : 0
      }
    };
    return (
        <div className="popup-wrapper container">
            <div className="new-event-popup">
                <h3>Dodaj wizytę</h3>
                <form className="basic-form">
                    <div className="section">
                        <SelectClient version={version} editMode={true} client={client} setClient={setClient} vehicle={vehicle} setVehicle={setVehicle} setUpdateClients={setUpdateClients} updateClients={updateClients} enableEdit/>
                    </div>
                    <div className="section">
                        <input 
                            className="form-control"
                            name="start"
                            type="datetime-local" 
                            value={moment(start).format().slice(0, -9)}
                            onChange={(e)=>{
                                if(e.target.value.slice(-2) === "30" || e.target.value.slice(-2) === "00"){
                                    setStart(moment(e.target.value))
                                }
                                else{
                                    alert("Wizyty mogą zaczynać się i kończyć co pół godziny licząc od pełnych godzin.")
                                }
                            }}></input>
                        <input 
                            className="form-control"
                            name="end"
                            type="datetime-local" 
                            value={moment(end).format().slice(0, -9)}
                            onChange={(e)=>{
                                if(e.target.value.slice(-2) === "30" || e.target.value.slice(-2) === "00"){
                                    setEnd(moment(e.target.value))
                                }
                                else{
                                    alert("Wizyty mogą zaczynać się i kończyć co pół godziny licząc od pełnych godzin.")
                                }
                            }}></input>
                    </div>
                    <div className="section">
                        {stations.length > 1 && <select
                            className="form-select"
                            onChange={(e) => {
                                setAddedStationId(parseInt(e.target.value))}}>
                                <option>stanowisko *</option>
                            {mappedStations(stations)}
                        </select>}
                        {/* {<Services version={version} services={services} setServices={setServices} station={station()} editMode={true}/>} */}
                        <input 
                            disabled={!station()}
                            className="form-control"
                            type="text"
                            placeholder="dodatkowe informacje"
                            value={description}
                            onChange={(e) => {setDescription(e.target.value)}}>
                        </input>
                    </div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={()=>{setShowNewEventPopup(false)}}>Anuluj
                    </button>
                    <button
                        className="btn btn-outline-primary"
                        onClick={(e)=>{handleAddEvent(e)}}>Dodaj
                    </button>
                </form>
            </div>
        </div>
    )
}

export default AddNewEvent;
