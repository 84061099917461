import { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const SelectClient = (props) => {
    const {version, editMode, client, setClient, vehicle, setVehicle, updateClients, setUpdateClients, enableEdit} = props;
    const [cookies, setCookie] = useCookies();
    const [clients, setClients] = useState();
    const [newClientName, setNewClientName] = useState();
    const [newBrand, setNewBrand] = useState("");
    const [newModel, setNewModel] = useState("");
    const [newPlateNumber, setNewPlateNumber] = useState("");
    const [newVin, setNewVin] = useState("11111111111111111");
    const [newPhone, setNewPhone] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [existingClient, setExistingClient] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("")
    let navigate = useNavigate();
    
    const newVehicle = {
        "brand": newBrand,
        "model": newModel,
        "plateNumber": newPlateNumber,
        "vin": newVin
    }
    const newClient = {
        "name": newClientName,
        "email": newEmail,
        "phone": newPhone,
        "calendar_vehicles": []
    }
    useEffect(()=>{
        if(client){
            axios.get('https://'+ version + '.soft99.eu/calendar-clients/'+client.id,
            {
                headers: { Authorization: `Bearer ` + cookies.JWTToken }
            })
            .then(response=>{
                setClient(response.data)
                setCookie('Client', response.data, { path: '/'})
                setVehicle(response.data.calendar_vehicles.find(singleVehicle=>singleVehicle.id === vehicle.id))
            })
            .then(()=>{
                setIsLoading(false)
            })
        }
        else{
            setIsLoading(false)
        }
    },[updateClients])

    const compareStrings = (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    }
      
    const compare = (a, b) => {
        const splitA = a.name.split(" ");
        const splitB = b.name.split(" ");
        const lastA = splitA[splitA.length - 1];
        const lastB = splitB[splitB.length - 1];
      
        return lastA === lastB ?
          compareStrings(splitA[0], splitB[0]) :
          compareStrings(lastA, lastB);
    }
    const mappedClients = clients && clients.sort(compare).map((singleClient)=>{
        return(
            <option 
                key={singleClient.id}
                value={JSON.stringify(singleClient)}>
                    {singleClient.name}
            </option>
        )
    })
    const mappedVehicles = (client && client.calendar_vehicles && existingClient) && client.calendar_vehicles.map((singleVehicle)=>{
        return(
            <option
                key={singleVehicle.id}
                value={JSON.stringify(singleVehicle)}>
                {singleVehicle.brand + " " + singleVehicle.model} {singleVehicle.plateNumber && "(" + singleVehicle.plateNumber + ")"}
            </option>
        )
    })
    useEffect(()=>{
            if(searchValue.length>=3){
                axios.get('https://'+ version + '.soft99.eu/calendar-clients/?name_contains='+searchValue,
                {
                    headers: { Authorization: `Bearer ` + cookies.JWTToken }
                })
                .then(response=>{
                    setClients(response.data)
                })
            }
    },[searchValue])

    const handleNewClient = () => {
        if(newClientName && newPhone && newEmail && newBrand && newModel && newVin && newPlateNumber){
            if( newVin.length===17 &&
                !newVin.includes(" ") &&
                !newVin.includes("O") &&
                !newVin.includes("Q") &&
                !newVin.includes("I") ){
                fetch('https://'+ version + '.soft99.eu/calendar-clients', {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ` + cookies.JWTToken, 
                },
                    body: JSON.stringify(newClient)
                })  
                .then(response => response.json())
                .then(data =>{
                    if(data){
                        let fetchedClient = data
                        fetch('https://'+ version + '.soft99.eu/calendar-vehicles', {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                'Accept':'application/json',
                                'Content-Type':'application/json',
                                'Authorization': `Bearer ` + cookies.JWTToken, 
                            },
                            body: JSON.stringify(newVehicle)
                            })
                            .then(response => response.json())
                            .then(data =>{
                                if(data){
                                    let fetchedVehicle = data
                                    fetchedClient.calendar_vehicles.push(fetchedVehicle)
                    
                                fetch('https://'+ version + '.soft99.eu/calendar-clients/' + fetchedClient.id, {
                                    method: "PUT",
                                    mode: 'cors',
                                    headers: {
                                        'Accept':'application/json',
                                        'Content-Type':'application/json',
                                        'Authorization': `Bearer ` + cookies.JWTToken, 
                                    },
                                     body: JSON.stringify(
                                        fetchedClient
                                    )
                                })
                                .then(response=>{
                                        if(response.ok){
                                            alert("Pomyślnie dodano nowego klienta.")
                                            setClient(fetchedClient)
                                            setCookie('Client', fetchedClient, { path: '/'})
                                            setVehicle(fetchedVehicle)
                                        }
                                    })
        
                                }
                            })
                        }
                        else{
                            alert("Coś poszło nie tak, sprawdź poprawność wszystkich danych klienta.")
                        }
                    })
                }
            else{
                alert("Kod VIN jest niepoprawny. Kod VIN musi składać się z 17. znaków i nie może zawierać spacji oraz liter: 'I', 'Q' oraz 'O'.")
            }
        }
        else{
            alert("Uzupełnij wszystkie pola.")
        }
    }
    
    return(
        <div>
            {!isLoading && 
                <div className="select-client">
                    {editMode && <select 
                        value={!existingClient ? "new" : "old"}
                        className="form-control"
                        onChange={(e)=>{
                            if(e.target.value==="new"){
                                setExistingClient(false)
                            }
                            else{
                                setExistingClient(true)
                                setVehicle(clients[0].calendar_vehicles[0])
                                setClient(clients[0])
                                setCookie('Client', clients[0], { path: '/'})
                            }}}>
                        {<option 
                            value="old">Istniejący klient</option>}
                        <option 
                            value="new">Nowy klient</option>
                    </select>}
 {/* =======================================  ISTNIEJĄCY KLIENT ======================================================================== */}
                    {existingClient ? 
                    <div>
                        {editMode && 
                        <div>
                            <input 
                                placeholder="szukaj"
                                className="form-control"
                                onChange={(e)=>{setSearchValue(e.target.value)}}
                                type="text">
                            </input>
                            <select
                                disabled={searchValue.length<3}
                                onChange={(e)=>{
                                    if(e.target.value!=="all"){
                                        setClient(JSON.parse(e.target.value))
                                        setCookie('Client', JSON.parse(e.target.value), { path: '/'})
                                        setVehicle(JSON.parse(e.target.value).calendar_vehicles[0])}
                                    }}
                                className="form-control"
                                type="text">
                                <option value="all">Wybierz</option>
                                {mappedClients}
                            </select>
                        </div>}
                        <span><b>Imię i nazwisko / nazwa:</b></span>
                        <div>
                            {client ? client.name : ""}
                        </div>
                        <span><b>Numer tel.:</b></span>
                        <div>
                            {client ? client.phone : ""}
                        </div>
                        <span><b>Email:</b></span>
                        <div>
                            {client ? client.email : ""}
                        </div>
                        <span><b>Pojazd:</b></span>
                        {editMode ? <select
                            defaultValue={vehicle && JSON.stringify(vehicle)}
                            className="form-control"
                            disabled={!client}
                            onChange={(e)=>{
                                setVehicle(JSON.parse(e.target.value))
                            }}>
                            {mappedVehicles}
                        </select> :
                        <div>
                            {vehicle && vehicle.brand + " " + vehicle.model} {vehicle && vehicle.plateNumber && vehicle.plateNumber} {vehicle && vehicle.vin && vehicle.vin}
                        </div>}
                        {enableEdit && client && <button 
                            className="btn btn-outline-secondary" 
                            onClick={()=>{
                                if(window.confirm("Klikając 'ok' przechodzisz do edytowania danych klienta. Jeżeli zamiast tego chcesz zmienić klienta przypisanego do wizyty kliknij 'Edytuj wizytę'.")){
                                    setUpdateClients(updateClients+1)
                                    navigate('/Client' + client.id)
                                }
                            }}>Edytuj dane klienta</button>}
                    </div>
                    :
 // =======================================  NOWY KLIENT ========================================================================
                    <div>
                        <span><b>Nazwa:</b></span>
                        {editMode ? <input
                            onChange={(e)=>{setNewClientName(e.target.value)}}
                            value={newClientName}
                            className="form-control"
                            type="text">
                        </input> : 
                        <div>{client.name}</div>}
                        <span><b>Numer tel.:</b></span>
                        {editMode ? <input 
                            onChange={(e)=>{setNewPhone(e.target.value)}}
                            value={newPhone}
                            className="form-control"
                            type="text">
                        </input> : 
                        <div>{client.phone}</div>}
                        <span><b>Email:</b></span>
                        {editMode ? <input
                            onChange={(e)=>{setNewEmail(e.target.value)}}
                            value={newEmail}
                            className="form-control"
                            type="text">
                        </input> : 
                        <div>{client.email}</div>}
                        <span><b>Pojazd:</b></span>
                        {editMode ? <input 
                            onChange={(e)=>setNewBrand(e.target.value)}
                            value={newBrand}
                            className="form-control"
                            type="text"
                            placeholder="marka">
                        </input> : 
                        <div>{vehicle.brand}</div>}
                        {editMode ? <input 
                            onChange={(e)=>setNewModel(e.target.value)}
                            value={newModel}
                            className="form-control"
                            type="text"
                            placeholder="model">
                        </input> : 
                        <div>{vehicle.model}</div>}
                        {editMode ? <input 
                            onChange={(e)=>setNewPlateNumber(e.target.value)}
                            value={newPlateNumber}
                            className="form-control"
                            type="text"
                            placeholder="numer rejestracyjny">
                        </input> : 
                        <div>{vehicle.plateNumber}</div>}
                        {editMode ? <input 
                            onChange={(e)=>setNewVin(e.target.value)}
                            value={newVin}
                            className="form-control"
                            type="text"
                            placeholder="VIN">
                        </input> : 
                        <div>{vehicle.vin}</div>}
                        <button 
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={()=>handleNewClient()}>Zatwierdź nowego klienta
                        </button>
                    </div>}
            </div>}
        </div>
    )
}

export default SelectClient
