import nextPage from '../style/images/right-arrow.png';
import prevPage from '../style/images/left-arrow.png';
import nextPageDisabled from '../style/images/right-arrow-disabled.png';
import prevPageDisabled from '../style/images/left-arrow-disabled.png';

const ListNavigation = (props) => {
    const {pageIndicator, setPageIndicator, itemsPerPage, setItemsPerPage, items} = props
    return(
        <div className="navigation">
            <div>
                <label 
                    htmlFor="itemsPerPage">Liczba wyników na stronę</label>
                <select 
                    defaultValue={itemsPerPage}
                    onChange={(e)=>{
                        setPageIndicator(0)
                        setItemsPerPage(parseInt(e.target.value))
                    }}
                    name="itemsPerPage">
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            <button
                className="btn btn-outline-primary"
                disabled={pageIndicator===0}
                onClick={()=>{setPageIndicator(0)}}>Pierwsza</button>
            <img src={pageIndicator!==0 ? prevPage : prevPageDisabled}
                onClick={()=>{
                    if(pageIndicator!==0){
                        setPageIndicator(pageIndicator-itemsPerPage)
                    }
                }}
            />
            <img src={items.length === itemsPerPage ? nextPage : nextPageDisabled}
                onClick={()=>{ 
                    if(items.length === itemsPerPage){
                        setPageIndicator(pageIndicator+itemsPerPage)
                    }
                }}
            />
        </div>
    )
}

export default ListNavigation