const mappedStations = (stations)=>{
    let result = [];
    if(stations){
        stations.map(singleStation => {
            result.push( <option key={singleStation.id} value={singleStation.id}>{singleStation.name}</option>);
        });
    }
    else{
        result.push(null);
    }
    return result;
}

export default mappedStations;
