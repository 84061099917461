import './style/style.css';
import { React, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';
import Stations from './components/Stations';
import Event from './components/Event';
import AdminsView from './components/admin/AdminsView';
import ManagersView from './components/manager/ManagersView';
import MenageUsers from './components/admin/ManageUsers';
import ManageServices from './components/admin/ManageServices';
import User from './components/admin/User';
import Shift from './components/Shift';
import Client from './components/Client';
import CalendarContainer from './components/CalendarContainer';
import LogIn from './components/LogIn';
import VehicleInspectionCard from './components/VehicleInspectionCard';
import EventsHistory from './components/EventsHistory';
import {momentLocalizer} from 'react-big-calendar';
import moment from "moment";
import Unauthorized from './components/Unauthorized';
import { useCookies } from 'react-cookie';
export let stations2;

function App() {
  const localizer = momentLocalizer(moment);
  let version

  const setVersion = () => {
    if (process.env.NODE_ENV !== 'production') {
      version = "test.cms"
    }
    else{
      version = "cms"
    }
  }
  setVersion()

  const [cookies, setCookie] = useCookies();
  const [allShifts, setAllShifts] = useState();
  const [allUsers, setAllUsers] = useState();
  const [allStations, setAllStations] = useState();
  const [eventsToRender, setEventsToRender] = useState();
  const [clientsToRender, setClientsToRender] = useState();
  const [updateUsers, setUpdateUsers] = useState(0)
  const [updateEvents, setUpdateEvents] = useState(0)
  const [updateShifts, setUpdateShifts] = useState(0)
  const [updateStations, setUpdateStations] = useState(0)
  const [updateClients, setUpdateClients] = useState(0)
  const [date, setDate] = useState(new Date)
  let startOfCurrentWeek = moment(moment(new Date).startOf('week')).format().slice(0, 10)
  let endOfCurrentWeek = moment(moment(new Date).endOf('week')).add(1, 'days').format().slice(0, 10)
  let startOfWeek = moment(moment(date).startOf('week')).format().slice(0, 10)
  let endOfWeek = moment(moment(date).endOf('week')).add(1, 'days').format().slice(0, 10)

  useEffect(()=>{
    if(cookies.JWTToken){
      const query = qs.stringify({
        _where: { 
            isDeleted: false,
            _or: [
                [
                  { start_gte: startOfCurrentWeek },
                  { end_lte: endOfCurrentWeek }
                ],
                [ 
                  { start_gte: startOfWeek },
                  { end_lte: endOfWeek }
                ],
            ],
        },
      });
      axios.get(`https://${version}.soft99.eu/calendar-events/?_limit=-1&${query}`,
      {
          headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
          if(response.status===200){
              response.data.map((event)=>{
                event.start = new Date(event.start);
                event.end = new Date(event.end);
                return event;
              })
              if(cookies.Event){
                response.data.push(cookies.Event)
              }
              setEventsToRender(response.data);
          }
      })
    }
  },[updateEvents, date, cookies.JWTToken])

  useEffect(()=>{
    if(cookies.JWTToken){
      axios.get('https://'+ version + '.soft99.eu/calendar-clients',
      {
          headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
          if(response.status===200){
            if(cookies.Client){
              response.data.push(cookies.Client)
            }
              setClientsToRender(response.data);
          }
      })
    }
  },[updateClients, cookies.JWTToken])

  useEffect(()=>{
    if(cookies.JWTToken){
      axios.get('https://'+ version + '.soft99.eu/calendar-stations?isDeleted_eq=false',
      {
          headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
          if(response.status===200){
              setAllStations(response.data);
          }
      })
    }
  },[updateStations, cookies.JWTToken])

  const mappedStations = allStations && allStations.map((station)=>{
    return(
      <Route key={station.id} path={"/" + station.name} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ?
        <CalendarContainer station={station} localizer={localizer} setEventsToRender={setEventsToRender} setClientsToRender={setClientsToRender} updateEvents={updateEvents} setUpdateEvents={setUpdateEvents} allShifts={allShifts} setAllShifts={setAllShifts} updateShifts={updateShifts} setUpdateShifts={setUpdateShifts} updateClients={updateClients} setUpdateClients={setUpdateClients} date={date} setDate={setDate} version={version}/> :
        <Unauthorized />}/>
    )
  })
  const mappedEvents = eventsToRender && eventsToRender.map((event)=>{
    return(
        <Route key={event.id} path={"/Event" + event.id} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ?
          <Event passedEvent={event} setClientsToRender={setClientsToRender} clientsToRender={clientsToRender} version={version} updateEvents={updateEvents} setUpdateEvents={setUpdateEvents} setUpdateClients={setUpdateClients} updateClients={updateClients}/> :
          <Unauthorized />}/>
    )
  })
  const mappedClients = clientsToRender && clientsToRender.map((client)=>{
    return(
      <Route key={client.id} path={"/Client" + client.id} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ?
        <Client passedClient={client} clientsToRender={clientsToRender} setClientsToRender={setClientsToRender} version={version} setUpdateEvents={setUpdateEvents} updateEvents={updateEvents} updateClients={updateClients} setUpdateClients={setUpdateClients}/> :
        <Unauthorized />}/>
    )
  })
  const mappedVICs = eventsToRender && eventsToRender.map((event)=>{
    return(
        <Route key={"VIC"+event.id} path={"/EventVIC" + event.id} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ?
          <VehicleInspectionCard passedEvent={event} version={version} setUpdateEvents={setUpdateEvents} updateEvents={updateEvents} allUsers={allUsers} setUpdateClients={setUpdateClients} updateClients={updateClients}/> :
          <Unauthorized />}/>
    )
  })
  const mappedSchedule = allShifts && allShifts.map((shift)=>{
    return(
      <Route key={shift.id} path={"/Shift" + shift.id} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ? 
        <Shift allShifts={allShifts} setAllShifts={setAllShifts} passedShift={shift} version={version}/> :
        <Unauthorized />}/>
    )
  })
  const mappedUsers = allUsers && allUsers.map((user)=>{
    return(
      <Route key={user.id} path={"/User" + user.id} element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ? 
        <User user={user} updateUsers={updateUsers} setUpdateUsers={setUpdateUsers} version={version}/> :
        <Unauthorized />}/>
    )
  })
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LogIn allUsers={allUsers} version={version}/>}/>
        </Routes>
        <Routes>
          <Route path="/Stations" element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ? 
            <Stations allStations={allStations} setAllStations={setAllStations} updateStations={updateStations} setUpdateStations={setUpdateStations} version={version}/> : 
            <Unauthorized/>}/>
          <Route path="/MenageUsers" element={cookies.user && ['admin'].includes(cookies.user.role) ?
            <MenageUsers updateUsers={updateUsers} setUpdateUsers={setUpdateUsers} allUsers={allUsers} setAllUsers={setAllUsers} version={version}/> :
            <Unauthorized />}/>
          <Route path="/ManageServices" element={cookies.user && ['admin'].includes(cookies.user.role) ?
            <ManageServices version={version}/> :
            <Unauthorized />}/>
          <Route path="/AdminsView" element={cookies.user && ['admin'].includes(cookies.user.role) ? 
            <AdminsView setDate={setDate}/> : 
            <Unauthorized/>}/>
          <Route path="/MainCalendar" element={cookies.user && ['manager', 'admin'].includes(cookies.user.role) ? 
            <CalendarContainer mainCalendar localizer={localizer} setEventsToRender={setEventsToRender} setClientsToRender={setClientsToRender} updateEvents={updateEvents} setUpdateEvents={setUpdateEvents} allShifts={allShifts} setAllShifts={setAllShifts} updateShifts={updateShifts} setUpdateShifts={setUpdateShifts} updateClients={updateClients} setUpdateClients={setUpdateClients} date={date} setDate={setDate} version={version}/> :
            <Unauthorized />}/>
          <Route path="/ManagersView" element={cookies.user && ['manager'].includes(cookies.user.role) ? 
            <ManagersView authorize={['manager']} /> :
            <Unauthorized />}/>
          {allStations && <Route path="/EventsHistory" element={cookies.user && ['operator', 'manager', 'admin'].includes(cookies.user.role) ? 
            <EventsHistory version={version} allStations={allStations} setEventsToRender={setEventsToRender}/> : 
            <Unauthorized/>}/>}
          {mappedStations}
          {mappedEvents}
          {mappedUsers}
          {mappedSchedule}
          {mappedVICs}
          {mappedClients}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
