import Navbar from "./Navbar";

const Unauthorized = () => {

    return <div>
        <Navbar />
        Ta strona jest zarezerwowana dla innych poziomów użytkownika niż Twój. Jeżeli masz uprawnienia do wyświetlenia tej strony spróbuj zalogować się ponownie.
    </div>
}

export default Unauthorized;
