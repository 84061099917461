import moment from "moment";

const isAtFreeTimeSlot = (event, allEvents, editing) => {

  const start = typeof event.start === "object" ? event.start.toISOString() : event.start;
  const end = typeof event.end === "object" ? event.end.toISOString() : event.end;
  const id = event.id;
  const station = event.calendar_station;
  const matchingStationEventList = allEvents.filter(singleEvent => {
    return singleEvent.calendar_station.id === station.id;
  });
  if (start < end) {
    if (matchingStationEventList.length === 0) {
      return true;
    }
    else {
      const xStart = moment(start)
      const xEnd = moment(end)
      for (let i = 0; i < matchingStationEventList.length; i++) {

        // let camparedEventStart  = matchingStationEventList[i].start
        // let camparedEventEnd    = matchingStationEventList[i].end
        // obiekt moment daje więcej narzędzi
        let Si  = moment(matchingStationEventList[i].start)
        let Ei  = moment(matchingStationEventList[i].end)
        if (
          // (camparedEventStart<start && camparedEventEnd>start) ||
          // (camparedEventStart<end && camparedEventEnd>end) || 
          // (camparedEventStart>start && camparedEventEnd<end)
          // !(end <= camparedEventStart || start >= camparedEventEnd)
             xStart.isBetween(Si.add(2,'seconds'),Ei.add(-2,'seconds'),'minute',"()")
          || xEnd.isBetween(Si.add(2,'seconds'),Ei.add(-2,'seconds'),'minute',"()")
          || Si.isBetween(xStart.add(2,'seconds'),xEnd.add(-2,'seconds'),'minute',"()")
          || Ei.isBetween(xStart.add(2,'seconds'),xEnd.add(-2,'seconds'),'minute',"()")
        ) {

          if (matchingStationEventList[i].id === id && editing) {
            if (i === matchingStationEventList.length - 1) {
              return true;
            }
          }
          else {
            alert(`Wizyta koliduje z wizytą nr ${matchingStationEventList[i].id} na tym samym stanowisku.`);
            return false;
          }
        }
        else {
          if (i === matchingStationEventList.length - 1) {
            return true;
          }
        }
      }
    }
  }
  else {
    alert("Nieprawidłowe daty")
    return false;
  }
}
export default isAtFreeTimeSlot
