import { React, useState} from "react";
import mappedStations from "../utilities/mappedStations";
import mappedOperators from "../utilities/mappedOperators";
import { useCookies } from "react-cookie";

const AddNewShift = (props) => {
    const {start, end, stations, operators, setAllShifts, setShowNewEventPopup, updateShifts, setUpdateShifts, version } = props;
    const [cookies, setCookie] = useCookies();
    const [addedStation, setAddedStation] = useState();
    const [operator, setOperator] = useState();
    const station = () => {
        if(stations.length === 1){
            return stations[0];
        }
        else {
            for(let i=0; i<stations.length; i++){
                if(stations[i].id === addedStation){
                    return stations[i];
                }
            }
        }
    }
    const handleAddShift = (e) => {
        e.preventDefault();
        if(newShift.title && newShift.calendar_user && newShift.calendar_station){
            fetch('https://'+ version + '.soft99.eu/calendar-shifts', {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken, 
                },
                body: JSON.stringify(newShift)
            })
            .then((response)=>{
                if(response.ok){
                    newShift.start = new Date(newShift.start);
                    newShift.end = new Date(newShift.end);
                    setUpdateShifts(updateShifts+1)
                    setAllShifts((prev) => [...prev, newShift])
                }
            })
            setShowNewEventPopup(false);
        }
        else{
            alert("Uzupełnij wymagane pola (*)");
        }
    }
    const newShift = 
    {
      title: operator && operator.name,
      type: "shift",
      start: start,
      end: end,
      calendar_station : station(),
      calendar_user : operator && operator,
      isDeleted: false
    };
    return (
        <div className="popup-wrapper container">
            <div className="new-shift-popup">
                <form>
                    <h3>Dodaj zmianę</h3>
                    {stations.length > 1 && <select
                        className="form-control"
                        onChange={(e) => {
                            setAddedStation(parseInt(e.target.value))}}>
                            <option>stanowisko *</option>
                            {mappedStations(stations)}
                    </select>}
                    {station() && <select
                        className="form-control"
                        onChange={(e) => setOperator(operators.filter(singleOperator=>singleOperator.id === parseInt(e.target.value))[0])}>
                        <option>pracownik *</option>
                        {mappedOperators(operators, station())}
                    </select>}
                    <button
                        className="btn btn-outline-primary"
                        onClick={(e)=>{handleAddShift(e)}}>Dodaj
                    </button>
                    <button 
                        className="btn btn-outline-secondary"
                        onClick={()=>{setShowNewEventPopup(false)}}>Anuluj
                    </button>
                </form>
            </div>
        </div>
        
    )
}

export default AddNewShift;
