const shiftPropGetter = (event) => ({
    ...(event.type.includes('shift') && {
      style: 
        { 
            backgroundColor: 'rgb(255, 215, 205)',
            color: 'rgb(0, 0, 0)',
            fontWeight: 'bolder'
        },
    })
  }
)
export default shiftPropGetter;
