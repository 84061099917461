import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Views, DateLocalizer } from 'react-big-calendar';
import axios from 'axios';
import qs from 'qs'
import eventPropGetter from '../utilities/eventPropGetter';
import shiftPropGetter from '../utilities/shiftPropGetter';
import AddNewEvent from './AddNewEvent';
import AddNewShift from './AddNewShift';
import { useNavigate } from 'react-router-dom';
import mappedStations from '../utilities/mappedStations';
import mappedOperators from '../utilities/mappedOperators';
import Navbar from './Navbar';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import  'moment/locale/pl';

const CalendarContainer = (props) => {
  const { mainCalendar, station, localizer, setEventsToRender, setClientsToRender, updateEvents, setUpdateEvents, allShifts, setAllShifts, updateShifts, setUpdateShifts, updateClients, setUpdateClients, date, setDate, version} = props;
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const [allUsers, setAllUsers] = useState();
  const [allStations, setAllStations] = useState();
  const [currentStationId, setCurrentStationId] = useState(station ? station.id : -1)
  const [operatorId, setOperatorId] = useState(-1)
  const operators = allUsers && allUsers.filter((user)=>{return user.role === "operator"});
  const [showNewEventPopup, setShowNewEventPopup] = useState(false);
  const [newEventStart, setNewEventStart] = useState();
  const [newEventEnd, setNewEventEnd] = useState();
  const [scheduleMode, setScheduleMode] = useState(false);
  const [allEvents, setAllEvents] = useState();
  const [isLoading, setIsLoading] = useState(true)
  let startOfCurrentWeek = moment(moment(new Date).startOf('week')).format().slice(0, 10)
  let endOfCurrentWeek = moment(moment(new Date).endOf('week')).add(1, 'days').format().slice(0, 10)
  let startOfWeek = moment(moment(date).startOf('week')).format().slice(0, 10)
  let endOfWeek = moment(moment(date).endOf('week').format()).add(1, 'days').format().slice(0, 10)
  const messages = {
    week: 'Tydzień',
    work_week: 'Tydzień roboczy',
    day: 'Dzień',
    month: 'Miesiąc',
    previous: 'Poprzedni',
    next: 'Następny',
    today: 'Dzisiaj',
    agenda: 'Lista',
    noEventsInRange: 'Brak wydarzeń w tym okresie.',
  }

  useEffect(()=>{
    if(cookies.JWTToken){
      let stationQuery
      if(currentStationId !== -1) {
        stationQuery = {'calendar_station.id': currentStationId}
      }
      
      const query = qs.stringify({
        _where: [ 
            {isDeleted: false},
            stationQuery,
            {_or: [
                [
                  { start_gte: startOfCurrentWeek },
                  { end_lte: endOfCurrentWeek }
                ],
                [ 
                  { start_gte: startOfWeek },
                  { end_lte: endOfWeek }
                ],
            ]},
          ],
      });

      axios.get(`https://${version}.soft99.eu/calendar-events/?_limit=-1&${query}`,
      {
          headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
        response.data.map((event)=>{
          event.start = new Date(event.start);
          event.end = new Date(event.end);
          return event;
        })
        setClientsToRender(response.data.map(event=>event.calendar_client))
        setEventsToRender(response.data)
        setAllEvents(response.data)
        setIsLoading(false)
      })
    }
  },[updateEvents, date, currentStationId])

  useEffect(()=>{
    if(cookies.JWTToken){
      axios.get('https://'+ version + '.soft99.eu/calendar-stations?isDeleted_eq=false'
      ,
      {
        headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
        setAllStations(response.data)
      })
    }
  },[])

  useEffect(()=>{
    if(cookies.JWTToken){
      let stationQuery
      if(currentStationId !== -1){
        stationQuery = {'calendar_stations_in': currentStationId}
      }
      const query = qs.stringify({
          _where: [ 
              {isDeleted: false},
              {role: 'operator'},
              stationQuery,
            ],
        });
      axios.get(`https://${version}.soft99.eu/calendar-users?${query}`
      ,
      {
        headers: { Authorization: `Bearer ` + cookies.JWTToken }
      })
      .then(response=>{
        setAllUsers(response.data)
      })
    }
  },[currentStationId])

  useEffect(()=>{
    
    if(cookies.JWTToken){
      let operatorsQuery
      if(operatorId !== -1){
        operatorsQuery = {'calendar_user.id': operatorId}
      }
      
      let stationQuery
      if(currentStationId !== -1){
        stationQuery = {'calendar_station.id': currentStationId}
      }
      const query = qs.stringify({
          _where: [ 
              {isDeleted: false},
              operatorsQuery,
              stationQuery,
              {_or: [
                  [
                    { start_gte: startOfCurrentWeek },
                    { end_lte: endOfCurrentWeek }
                  ],
                  [
                    { start_gte: startOfWeek },
                    { end_lte: endOfWeek }
                  ],
              ]},
            ],
        });
        axios.get(`https://${version}.soft99.eu/calendar-shifts?${query}`
        ,
        {
          headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
          response.data.map((shift)=>{
            shift.start = new Date(shift.start);
            shift.end = new Date(shift.end);
            return shift;
          })
          setAllShifts(response.data)
        })
      }
  },[operatorId, currentStationId, date])

  const handleSelectSlot =({ start, end }) => {
    if(cookies.user.role!=="operator" && allStations){
      setNewEventStart(start.toISOString());
      setNewEventEnd(end.toISOString());
      setShowNewEventPopup(true);
    }
  }
  
  const handleSelectEvent = useCallback(
    (event) =>{
      if(event.type === "shift"){
        navigate('/Shift'+ event.id)
      }
      else{
        navigate('/Event'+ event.id)
      }
      },
    []
  );
  return (
      <div className="calendar-container container">    
        <Navbar />
        <div className='row'>
          <h1 onClick={()=> {setScheduleMode(!scheduleMode)}}>{mainCalendar ? "Główny kalendarz" : station.name}{scheduleMode ? " - Grafik (zmień)" : " - Wizyty (zmień)"}</h1>
        </div>
        <div className='row'>
          <div className='col'>
            {mainCalendar && 
            <select
              className='form-select form-select-lg'
              onChange={(e)=>{
                setCurrentStationId(parseInt(e.target.value))
                }}>
              <option value={-1}>wszystkie stanowiska</option>
              {mappedStations(allStations)}
            </select>}
          </div>
          <div className='col'>
            {scheduleMode && <div>
              <select className='form-select' onChange={(e)=>{setOperatorId(parseInt(e.target.value))}}>
                <option value={-1}>wszyscy pracownicy</option>
                {station ? 
                mappedOperators(operators && operators.filter(singleOperator => {
                  let result
                   singleOperator.calendar_stations.map((singleStation)=>{
                    if(singleStation.id === station.id) {
                      result = singleStation
                    }
                  })
                  return singleOperator.calendar_stations.includes(result)
                }))
                :
                mappedOperators(operators)}
              </select>
            </div>}
          </div>
        </div>
        <div className='row'>
            <span hidden>{moment.locale('pl', {
                week: {
                    dow: 1,
                    doy: 1,
                },
            })}</span>
          {!isLoading ? <div className='calendar-container'>
              {allStations && <Calendar
                events={scheduleMode ? allShifts : allEvents}
                localizer={localizer}
                views={[Views.WEEK, Views.DAY]}
                defaultView={Views.WEEK}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onNavigate={newDate => setDate(newDate)}
                titleAccessor = {scheduleMode ? (e => e.title + " - " + e.calendar_station.name) : (e => e.calendar_client.name + " - " + e.calendar_station.name)}
                step={30}
                timeslots={2}
                selectable
                popup
                eventPropGetter={scheduleMode ? shiftPropGetter : eventPropGetter}
                dayLayoutAlgorithm={'no-overlap'}
                min={new Date().setHours(8,0,0)}
                max={new Date().setHours(21,0,0)}
                messages={messages}
              />}
              {scheduleMode ? 
              <div>
                {showNewEventPopup && <AddNewShift 
                  start={newEventStart}
                  end={newEventEnd}
                  stations={station ? [station] : allStations}
                  setShowNewEventPopup={setShowNewEventPopup}
                  operators={operators}
                  setAllShifts={setAllShifts}
                  updateShifts={updateShifts}
                  setUpdateShifts={setUpdateShifts}
                  version={version}
                />}
              </div>:
              <div>
                {showNewEventPopup && <AddNewEvent
                  passedStart={newEventStart}
                  passedEnd={newEventEnd}
                  setShowNewEventPopup={setShowNewEventPopup}
                  stations={station ? [station] : allStations}
                  updateEvents={updateEvents}
                  setUpdateEvents={setUpdateEvents}
                  updateClients={updateClients}
                  setUpdateClients={setUpdateClients}
                  version={version}
              />}
            </div>}
          </div>
          :
          <div>
            <h2>Loading...</h2>
          </div>}
      </div>
    </div>
  )
}

CalendarContainer.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}
export default CalendarContainer;
