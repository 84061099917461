import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

const EditService = (props) => {
    const {version, serviceToEdit, setServiceEditMode} = props
    const [cookies, setCookie] = useCookies();
    const [name, setName] = useState(serviceToEdit.name)
    const [stations, setStations] = useState()
    const [station, setStation] = useState(serviceToEdit.calendar_station)
    useEffect(()=>{
        axios.get(`https://${version}.soft99.eu/calendar-stations/?isDeleted_eq=false`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setStations(response.data);
            }
        })
    },[])
    const mappedStations = stations && stations.map((singleStation)=>{
        return(
            <option 
                key={singleStation.id}
                selected={singleStation.id === serviceToEdit.calendar_station.id}
                value={JSON.stringify(singleStation)}>
                {singleStation.name}
            </option>
        )
    })
    const editedService = {
        "name": name,
        "calendar_station": station
    }
    const saveService = (e) => {
        if(name && station){
            fetch('https://'+ version + '.soft99.eu/calendar-services/'+serviceToEdit.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify(editedService)
            })
        }
        else{
            e.preventDefault();
            alert("Wszystkie pola muszą być uzupełnione.")
        }
    }
    return(
        <div className="edit-service container">
            <form className="content">
                <h4>Wprowadź nowe dane usługi:</h4>
                <input 
                    placeholder="nazwa"
                    maxlength="40"
                    className="form-control"
                    value={name}
                    onChange={(e)=>setName(e.target.value)}></input>
                <select
                    className="form-select"
                    onChange={(e)=>{
                        setStation(JSON.parse(e.target.value).id)
                    }}>
                    {mappedStations}
                </select>
                <div className="edit-service-buttons">
                    <button
                        className="btn btn-danger"
                        onClick={()=>setServiceEditMode(false)}>Anuluj</button>
                    <button
                        className="btn btn-success"
                        onClick={(e)=>saveService(e)}>Zapisz</button>
                </div>
            </form>
        </div>
    )
}

export default EditService