import { useEffect, useState } from "react";
import axios from "axios";
import qs from 'qs';
import Navbar from "../Navbar";
import AddNewService from "./AddNewService";
import EditService from "./EditService";
import ListNavigation from "../ListNavigation";
import { useCookies } from "react-cookie";
import bin from '../../style/images/bin.png';
import edit from '../../style/images/edit.png';

const ManageServices = (props) => {
    const {version} = props
    const [cookies, setCookie] = useCookies()
    const [isLoading, setIsLoading] = useState(true)
    const [stations, setStations] = useState()
    const [stationFilter, setStationFilter] = useState()
    const [services, setServices] = useState()
    const [searchValue, setSearchValue] = useState("")
    const [pageIndicator, setPageIndicator] = useState(0)
    const [servicesPerPage, setServicesPerPage] = useState(10)
    const [updateServices, setUpdateServices] = useState(0)
    const [serviceToEdit, setServiceToEdit] = useState()
    const [serviceEditMode, setServiceEditMode] = useState(false)
    const [showAddServicePopup, setShowAddServicePopup] = useState(false)

    useEffect(()=>{
        axios.get(`https://${version}.soft99.eu/calendar-stations/?isDeleted_eq=false`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setStations(response.data);
            }
        })
    },[])
    useEffect(()=>{
        const query = qs.stringify({
            _where: [
                {isActive: true},
                {'calendar_station.id': stationFilter},
                { name_contains: searchValue },
            ],
        });
        axios.get(`https://${version}.soft99.eu/calendar-services/?${query}&_sort=name:ASC&_limit=${servicesPerPage}&_start=${pageIndicator}`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setServices(response.data);
                setIsLoading(false)
            }
        })
    },[stationFilter, searchValue, pageIndicator, servicesPerPage, updateServices])

    const handleDeleteService = (id)=>{
        if(window.confirm("Czy na pewno chcesz usunąć tę usługę?")){
            fetch('https://'+ version + '.soft99.eu/calendar-services/'+id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify({isActive: false})
            })
            .then(()=>setUpdateServices(updateServices+1))
        }
    }

    const mappedStations = stations && stations.map((singleStation)=>{
        return(
            <option 
                key={singleStation.id}
                value={JSON.stringify(singleStation)}>
                {singleStation.name}
            </option>
        )
    })
    const mappedServices = services && services.map((singleService)=>{
        return(
            <li 
                className="service"
                key={singleService.id}>
                    <div>
                        {singleService.name}
                    </div>
                    <div>
                        {singleService.calendar_station.name}
                    </div>
                    <div>
                        <img
                            src={edit} 
                            onClick={()=>{
                                setServiceEditMode(true)
                                setServiceToEdit(singleService)
                            }}/>
                    </div>
                    <div>
                        <img onClick={()=>handleDeleteService(singleService.id)} src={bin}/>
                    </div>
            </li>
        )
    })
    
    return(
        <div className="manage-services container">
            {serviceToEdit && serviceEditMode && <EditService version={version} serviceToEdit={serviceToEdit} setServiceEditMode={setServiceEditMode}/>}
            {showAddServicePopup && <AddNewService version={version} setShowAddServicePopup={setShowAddServicePopup}/>}
            <div className="row">
                <Navbar />
            </div>
            {!isLoading && <div>
                <div className="row">
                    <div className="col-sm-2">
                        <h1>Usługi</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <select
                            className="form-select"
                            onChange={(e)=>{
                                if(e.target.value==="all"){
                                    setStationFilter()
                                }
                                else{
                                    setStationFilter(JSON.parse(e.target.value).id)
                                }
                                }}>
                            <option
                                value={"all"}>wszystkie stanowiska</option>
                            {mappedStations}
                        </select>
                    </div>
                    <div className="col-sm-4">
                        <input
                            className="form-control"
                            onChange={(e)=>{
                                setSearchValue(e.target.value)}}
                            placeholder="wyszukaj"
                            type="text">
                        </input>
                    </div>
                    <div className="col-sm-4 button-column">
                        <button 
                            onClick={()=>{setShowAddServicePopup(true)}}
                            className="btn btn-outline-primary">
                            Dodaj usługę
                        </button>
                    </div>
                </div>
                <div className="ul-custom-container">
                    <ul className="custom-ul">
                        <li className="first-item">
                            <div>
                                <b>Nazwa</b>
                            </div>
                            <div>
                                <b>Stanowisko</b>
                            </div>
                        </li>
                        {mappedServices}
                    </ul>
                </div>
                <ListNavigation pageIndicator={pageIndicator} setPageIndicator={setPageIndicator} itemsPerPage={servicesPerPage} setItemsPerPage={setServicesPerPage} items={services}/>
            </div>}
        </div>
    )
}

export default ManageServices
