import {React, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import isAtFreeTimeSlot from "../utilities/isOnFreeTimeSlot";
import Navbar from "./Navbar";
import SelectClient from "./SelectClient";
import Services from "./Services";

const Event = (props) => {
    const {passedEvent, clientsToRender, setClientsToRender, setAllEvents, version, updateEvents, setUpdateEvents, updateClients, setUpdateClients} = props;
    let navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const [updateEvent, setUpdateEvent] = useState(0)
    const [event, setEvent] = useState(passedEvent);
    const [editMode, setEditMode] = useState(false);
    const [client, setClient] = useState(event.calendar_client);
    let title = client.name;
    const [start, setStart] = useState(event.start);
    const [end, setEnd] = useState(event.end);
    const [services, setServices] = useState(event.calendar_services);
    const [description, setDescription] = useState(event.resources.description);
    const [rateDescription, setRateDescription] = useState(event.resources.rateDescription);
    const [vehicle, setVehicle] = useState(event.calendar_vehicle)
    const [rate, setRate] = useState(event.resources.rate);    
    let startOfWeek = moment(moment(new Date(start)).startOf('week').toDate()).format().slice(0, 10)
    let endOfWeek = moment(moment(start).endOf('week').format()).add(1, 'days').format().slice(0, 10)
    
    useEffect(()=>{
        axios.get('https://'+ version + '.soft99.eu/calendar-events/'+event.id,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            response.data.start = new Date(response.data.start)
            response.data.end = new Date(response.data.end)
            setEvent(response.data)
            clientsToRender.push(response.data.calendar_client)
            setClientsToRender([...clientsToRender])
            setCookie('Event', response.data, { path: '/' })
        })
    },[updateEvent, updateEvents])
    
    const editedEvent = {
        id: event.id,
        title: title,
        start: start,
        end: end,
        calendar_client: client && client.id,
        calendar_vehicle: vehicle && vehicle.id,
        calendar_station : event.calendar_station,
        calendar_services: services,
        updatedBy: cookies.user.id,
        resources : {
            description: description,
            rateDescription : rateDescription,
            rate : rate
        }
    }

    const handleDeleteEvent = () => {
        if(window.confirm("Czy na pewno chcesz usunąć tę wizytę?")){
            fetch('https://'+ version + '.soft99.eu/calendar-events/' + event.id, {
            method: "PUT",
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': `Bearer ` + cookies.JWTToken, 
            },
            body: JSON.stringify({
                isDeleted : true,
            })
            })
            .then((response) =>{
                  if(response.status === 200){
                    navigate('/MainCalendar')
                  }
            })
        }
    }
    const editEvent = () => {
        axios.get('https://'+ version + '.soft99.eu/calendar-events/'+
        '?_where[0][start_gte]='+ startOfWeek + 
        '&_where[0][end_lte]='+ endOfWeek + 
        '&isDeleted=false&_limit=-1',
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            editedEvent.start = new Date(editedEvent.start)
            editedEvent.start = editedEvent.start.toISOString();
            editedEvent.end = new Date(editedEvent.end)
            editedEvent.end = editedEvent.end.toISOString();
            if(editMode && isAtFreeTimeSlot(editedEvent, response.data, true)){
                if( title && /*services[0] &&*/ editedEvent.calendar_station){
                    fetch('https://'+ version + '.soft99.eu/calendar-events/' + editedEvent.id, {
                        method: "PUT",
                        mode: 'cors',
                        headers: {
                            'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Authorization': `Bearer ` + cookies.JWTToken, 
                        },
                        body: JSON.stringify(editedEvent)
                    }
                        )
                        .then(()=>{
                            if(response.status===200){
                                editedEvent.start = new Date(editedEvent.start);
                                editedEvent.end =  new Date(editedEvent.end);
                                // setAllEvents((prev) => [...prev, editedEvent])
                                setUpdateEvent(updateEvent+1)
                                setUpdateEvents(updateEvents+1)
                                setEditMode(false);
                            }
                            else{
                                alert("Coś poszło nie tak, sprócuj jeszcze raz.")
                            }
                        }
                    )
                }
                else{
                    alert("Uzupełnij wymagane pola (*)")
                }
            }
        })
    }
    const handleEditSaveButton = () => {
        if(editMode){
            editEvent()
        }
        else{
            setEditMode(true);
        }
    }
    return (
        <div>
            <Navbar />
            {event && <div className="event container">
                <div className="row">
                <SelectClient version={version} editMode={passedEvent.calendar_inspection_card ? false : editMode} client={client} setClient={setClient} vehicle={vehicle} setVehicle={setVehicle} setUpdateClients={setUpdateClients} updateClients={updateClients} enableEdit/>
                    <span>
                        <label htmlFor="start"><b>Początek: </b></label>
                        {editMode ? 
                            <input 
                                className="form-control"
                                name="start"
                                type="datetime-local"
                                value={typeof start === "string" ? start : moment(start.toISOString()).format().slice(0, -9)}
                                onChange={(e)=>{
                                    if(e.target.value.slice(-2) === "30" || e.target.value.slice(-2) === "00"){
                                        setStart(e.target.value)
                                    }
                                    else{
                                        alert("Wizyty mogą zaczynać się co pół godziny licząc od pełnych godzin.")
                                    }
                                    }}></input> : 
                                event.start.toString().substring(0, 21)}</span>
                    <span>
                        <label htmlFor="end"><b>Koniec: </b></label>
                        {editMode ? 
                            <input 
                                className="form-control"
                                name="end"
                                type="datetime-local" 
                                value={typeof end === "string" ? end : moment(end.toISOString()).format().slice(0, -9)}
                                onChange={(e)=>{
                                    if(e.target.value.slice(-2) === "30" || e.target.value.slice(-2) === "00"){
                                        setEnd(e.target.value)
                                    }
                                    else{
                                        alert("Wizyty mogą zaczynać się co pół godziny licząc od pełnych godzin.")
                                    }
                                }}></input> : 
                                event.end.toString().substring(0, 21)}</span>
                    
                    {/* <label htmlFor="services"><b>Usługi: </b></label>
                    <Services name="services" version={version} services={services} setServices={setServices} station={event.calendar_station} editMode={editMode}/> */}
                    <span>
                        <label htmlFor="description"><b>Dodatkowe informacje: </b></label>
                        {editMode ? 
                            <input 
                                className="form-control"
                                name="description"
                                type="text" 
                                value={description}
                                onChange={(e)=>{setDescription(e.target.value)}}></input> : 
                            event.resources.description}</span>
                    <span>
                        <label><b>Stanowisko: </b></label>{event.calendar_station.name}</span>
                        {editMode ? 
                        <span>
                            <input 
                                className="form-control"
                                type="text" 
                                onChange={(e)=>{setRateDescription(e.target.value)}} 
                                placeholder="ocena wizyty"></input>
                        </span> :
                        <span><label><b>Ocena wizyty: </b></label>{event.resources.rateDescription}</span>}
                        {editMode ? 
                        <span>
                            <input 
                                className="form-control"
                                type="number" 
                                onChange={(e)=>{setRate(e.target.value)}} 
                                placeholder="ocena 1-10" 
                                min={1} 
                                max={10}></input>
                        </span> : 
                    <span><label><b>Ocena 1-10: </b></label>{event.resources.rate}</span>}
                    <span><label><b>Utworzono: </b></label>{new Date(passedEvent.created_at).toString().substring(0, 21)} {passedEvent.createdBy && "przez " + passedEvent.createdBy.name}</span>
                    <span><label><b>Ostatnia edycja: </b></label>{new Date(passedEvent.updated_at).toString().substring(0, 21)} {passedEvent.updatedBy && "przez " + passedEvent.updatedBy.name}</span>
                </div>
                {cookies.user.role !== "operator" && <div className="row">
                    {/* <button className="btn btn-outline-secondary">Przypomnij o wizycie</button> */}
                    <button className="btn btn-outline-secondary" onClick={()=>{handleEditSaveButton()}}>{editMode ? "Zapisz" : "Edytuj wizytę"}</button>
                    <button 
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={()=>{
                            navigate('/EventVIC'+event.id)}}>
                        {event.calendar_inspection_card ? "Otwórz KIP" : "Dodaj KIP"}
                    </button>
                    <button className="btn btn-outline-danger" onClick={()=>{handleDeleteEvent()}}>Usuń</button>
                </div>}
            </div>}
        </div>
    )
}

export default Event;
