import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import qs from 'qs';
import Navbar from "./Navbar";
import ListNavigation from "./ListNavigation";
import moment from "moment";
import sortDown from '../style/images/sort-down.png';
import sortUp from '../style/images/sort-up.png';
import close from '../style/images/close.png';

const EventsHistory = (props) => {
    const {version, allStations, setEventsToRender} = props;
    const [cookies, setCookie] = useCookies()
    let navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [sortingBy, setSortingBy] = useState("start")
    const [direction, setDirection] = useState("DESC")
    const [stationsIDs, setStationsIDs] = useState(allStations && allStations.map(station=>station.id))
    const [servicesToFilter, setServicesToFilter] = useState([])
    const [allEvents, setAllEvents] = useState()
    const [services, setServices] = useState()
    const [searchValue, setSearchValue] = useState("")
    const [pageIndicator, setPageIndicator] = useState(0)
    const [eventsPerPage, setEventsPerPage] = useState(10)
    const [displayAtributes, setDisplayAtributes] = useState(false)
    const [displayStations, setDisplayStations] = useState(false)
    const [displayServices, setDisplayServices] = useState(false)

    const [atributes, setAtributes] = useState(
            [
                {name: "title",
                displayedAs: "Nazwa",
                show: true,},
                {name: "email",
                displayedAs: "Email",
                show: false},
                {name: "phone",
                displayedAs: "Telefon",
                show: false},
                {name: "stationName",
                displayedAs: "Stanowisko",
                show: true},
                {name: "vehicle",
                displayedAs: "Pojazd",
                show: true},
                {name: "plateNumber",
                displayedAs: "Numer rej.",
                show: true},
                {name: "VIN",
                displayedAs: "VIN",
                show: false},
                {name: "start",
                displayedAs: "Termin wizyty",
                show: true},
                {name: "created_at",
                displayedAs: "Utworzono",
                show: true},
                {name: "createdBy",
                displayedAs: "Utworzył",
                show: false},
                {name: "updated_at",
                displayedAs: "Ostatnia edycja",
                show: false},
                {name: "updatedBy",
                displayedAs: "Edytował/a",
                show: false},
                {name: "services",
                displayedAs: "Usługi",
                show: true}
            ]
        )
    const mappedAtributes = atributes.map(atribute=>{
        return(
            <li key={atribute.name} className="single-atribute-item">
                <input 
                    checked={atribute.show}
                    onChange={()=>{
                        atribute.show = !atribute.show
                        setAtributes([...atributes])
                    }} 
                    name={atribute.name} 
                    type="checkbox"></input>
                <label htmlFor={atribute.name}>{atribute.displayedAs}</label>
            </li>
        )
    })
    const mappedServices = services && services.map(service=>{
        return(
            <li key={service.id} className="single-service-item">
                <span>
                    <input 
                        checked={servicesToFilter.includes(service.id)}
                        onChange={()=>{
                            if(servicesToFilter.includes(service.id)){
                                setServicesToFilter(servicesToFilter.filter(singleService=>singleService !== service.id))
                            }
                            else{
                                servicesToFilter.push(service.id)
                                setServicesToFilter([...servicesToFilter])
                            }
                        }} 
                        name={service.name}
                        type="checkbox"></input>
                    <label htmlFor={service.name}>{service.name}</label>
                </span>
                <span><b>{service.calendar_station.name}</b></span>
            </li>
        )
    })
    const mappedStations = allStations && allStations.map((station)=>{
        return(
            <li key={station.id}>
                <input 
                    checked={stationsIDs && stationsIDs.includes(station.id)}
                    onChange={(e)=>{
                        if(e.target.checked){
                            stationsIDs.push(station.id)
                            setStationsIDs([...stationsIDs])
                        }
                        else{
                            setStationsIDs(stationsIDs.filter(id => id !== station.id))
                        }
                    }} 
                    name={station.name}
                    type="checkbox"></input>
                <label htmlFor={station.name}>{station.name}</label>
            </li>
        )
    })
    const mappedEvents = (!isLoading) && allEvents.map((singleEvent)=>{
        return(
            <li className="event-list-item"
                onClick={()=>{navigate('/Event'+singleEvent.id)}}
                key={singleEvent.id}>
                {atributes.find(atribute=>atribute.name === "title").show && <div>{singleEvent.title}</div>}
                {atributes.find(atribute=>atribute.name === "email").show && <div>{singleEvent.calendar_client.email}</div>}
                {atributes.find(atribute=>atribute.name === "phone").show && <div>{singleEvent.calendar_client.phone}</div>}
                {atributes.find(atribute=>atribute.name === "stationName").show && <div>{singleEvent.calendar_station.name}</div>}
                {atributes.find(atribute=>atribute.name === "vehicle").show && <div>{singleEvent.calendar_vehicle.brand + " " + singleEvent.calendar_vehicle.model}</div>}
                {atributes.find(atribute=>atribute.name === "plateNumber").show && <div>{singleEvent.calendar_vehicle.plateNumber && singleEvent.calendar_vehicle.plateNumber}</div>}
                {atributes.find(atribute=>atribute.name === "VIN").show && <div>{singleEvent.calendar_vehicle.vin && singleEvent.calendar_vehicle.vin}</div>}
                {atributes.find(atribute=>atribute.name === "start").show && <div>{moment(singleEvent.start.toISOString()).format().slice(0, 10) + " " + moment(singleEvent.start.toISOString()).format().slice(-14, -9)}</div>}
                {atributes.find(atribute=>atribute.name === "created_at").show && <div>{moment(singleEvent.created_at).format().slice(0, 10) + " " + moment(singleEvent.created_at).format().slice(-14, -9)}</div>}
                {atributes.find(atribute=>atribute.name === "createdBy").show && <div>{singleEvent.createdBy && singleEvent.createdBy.name}</div>}
                {atributes.find(atribute=>atribute.name === "updated_at").show && <div>{moment(singleEvent.updated_at).format().slice(0, 10) + " " + moment(singleEvent.updated_at).format().slice(-14, -9)}</div>}
                {atributes.find(atribute=>atribute.name === "updatedBy").show && <div>{singleEvent.updatedBy && singleEvent.updatedBy.name}</div>}{atributes.find(atribute=>atribute.name === "updatedBy").show && <div>{singleEvent.createdBy && singleEvent.updatedBy.name}</div>}
                {atributes.find(atribute=>atribute.name === "services").show && 
                <div
                    className="single-event-service-list">{singleEvent.calendar_services[0] && singleEvent.calendar_services[0].name}
                    {singleEvent.calendar_services.length > 1 && <b>...+{singleEvent.calendar_services.length-1}</b>}
                    <div>
                        <ul>
                            {singleEvent.calendar_services.map(service=>{
                                return(
                                    <li key={service.id}>{service.name}</li>
                                )
                            })}
                        </ul>
                        {/* <button
                            className="clear"
                            onClick={()=>{
                                if(window.innerWidth > 700){
                                    alert()
                                }
                        }}>Więcej</button> */}
                    </div>
                </div>}
            </li>
        )
    })
    useEffect(()=>{
        const query = qs.stringify({
            _where: [
                {isDeleted: false},
                {'calendar_station.id_in': stationsIDs},
                {'calendar_services.id_in': servicesToFilter},
                {_or: [
                    { title_contains: searchValue },
                    { 'calendar_client.email_contains': searchValue },
                    { 'calendar_client.phone_contains': searchValue },
                    { 'calendar_vehicle.brand_contains': searchValue },
                    { 'calendar_vehicle.model_contains': searchValue },
                    { 'calendar_vehicle.vin_contains': searchValue },
                    { 'calendar_vehicle.plateNumber_contains': searchValue },
                ]},
            ],
        });
        axios.get(`https://${version}.soft99.eu/calendar-events/?${query}&_limit=${eventsPerPage}&_start=${pageIndicator}&_sort=${sortingBy}:${direction}`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                response.data.map((event)=>{
                  event.start = new Date(event.start);
                  event.end = new Date(event.end);
                  return event;
                })
                setAllEvents(response.data);
                setEventsToRender(response.data)
                setIsLoading(false)
            }
        })
    },[pageIndicator, eventsPerPage, searchValue, sortingBy, direction, stationsIDs, servicesToFilter])
    useEffect(()=>{
        const query = qs.stringify({
            _where: [
                {isActive: true},
                {'calendar_station.id_in': stationsIDs},
            ],
        });
        axios.get(`https://${version}.soft99.eu/calendar-services/?${query}&_limit=-1&_sort=calendar_station.name:ASC,name:ASC`,
        {
            headers: { Authorization: `Bearer ` + cookies.JWTToken }
        })
        .then(response=>{
            if(response.status===200){
                setServices(response.data)
            }
        })
    },[stationsIDs])
    const switchSorting = (sortingCategory) => {
        if(sortingBy===sortingCategory){
            if(direction==="ASC"){
                setDirection("DESC")
            }
            else{
                setDirection("ASC")
            }
        }
        else{
            setSortingBy(sortingCategory)
        }
    }
    return(
        <div className="container event-history">
            {!isLoading && <div className="event-history-content">
                <Navbar />
                <section className="row">
                    <div className="col-sm-6">
                        <input 
                            className="form-control search"
                            onChange={(e)=>{
                                setPageIndicator(0)
                                setSearchValue(e.target.value)}}
                            placeholder="wyszukaj"
                            type="text">
                        </input>
                        <div id="stations-wrapper" className="dropdown-list-wrapper">
                            <div className="dropdown-list-container">
                                <button className="btn btn-outline-primary" onClick={()=>setDisplayStations(!displayStations)}>{displayStations ? "ukryj stanowiska" :"filtruj wg stanowiska"}</button>
                                <ul className="dropdown-list" style={displayStations ? {display: "block"}: {display: "none"}}>
                                    {mappedStations}
                                </ul>
                            </div>
                        </div>
                        <div id="services-wrapper" className="dropdown-list-wrapper">
                            <div className="dropdown-list-container">
                                <button className="btn btn-outline-primary" onClick={()=>{setDisplayServices(!displayServices)}}>{displayServices ? "ukryj usługi" :"filtruj wg usług"}</button>
                                {servicesToFilter[0]&&<img src={close} onClick={()=>setServicesToFilter([])}/>}
                                <ul className="dropdown-list" style={displayServices ? {display: "block"}: {display: "none"}}>
                                    {mappedServices}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div id="atributes-wrapper" className="dropdown-list-wrapper">
                            <div className="dropdown-list-container">
                                <button className="btn btn-outline-primary" onClick={()=>setDisplayAtributes(!displayAtributes)}>{displayAtributes ? "ukryj atrybuty" :"pokaż atrybuty"}</button>
                                <ul className="dropdown-list"
                                    style={displayAtributes ? {display: "block"}: {display: "none"}}>
                                    {mappedAtributes}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="ul-custom-container">
                        <ul className="custom-ul">
                            <li
                                className="first-item event-list-item"
                                key="first-item">
                                {atributes.find(atribute=>atribute.name === "title").show && <b><div 
                                    onClick={()=>{switchSorting("title")}}>Nazwa
                                    {sortingBy==="title" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "email").show && <b><div
                                    onClick={()=>{switchSorting("calendar_client.email")}}>Email
                                    {sortingBy==="calendar_client.email" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                    </div></b>}
                                {atributes.find(atribute=>atribute.name === "phone").show &&<b><div>Telefon</div></b>}
                                {atributes.find(atribute=>atribute.name === "stationName").show && <b><div
                                    onClick={()=>{switchSorting("calendar_station.name")}}>Stanowisko
                                    {sortingBy==="calendar_station.name" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "vehicle").show && <b><div
                                    onClick={()=>{switchSorting("calendar_vehicle.brand")}}>Pojazd
                                    {sortingBy==="calendar_vehicle.brand" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "plateNumber").show && <b><div
                                    onClick={()=>{switchSorting("calendar_vehicle.plateNumber")}}>Numer rejestracyjny
                                    {sortingBy==="calendar_vehicle.plateNumber" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "VIN").show && <b><div
                                    onClick={()=>{switchSorting("calendar_vehicle.vin")}}>VIN
                                    {sortingBy==="calendar_vehicle.vin" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "start").show && <b><div
                                    onClick={()=>{switchSorting("start")}}>Termin wizyty
                                    {sortingBy==="start" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "created_at").show && <b><div
                                    onClick={()=>{switchSorting("created_at")}}>Utworzono
                                    {sortingBy==="created_at" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "createdBy").show && <b><div
                                    onClick={()=>{switchSorting("createdBy.name")}}>Utworzył/a
                                    {sortingBy==="createdBy.name" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "updated_at").show && <b><div
                                    onClick={()=>{switchSorting("updated_at")}}>Ostatnia edycja
                                    {sortingBy==="updated_at" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "updatedBy").show && <b><div
                                    onClick={()=>{switchSorting("updatedBy.name")}}>Edytował/a
                                    {sortingBy==="updatedBy.name" && <img src={direction==="ASC" ? sortUp : sortDown}/>}
                                </div></b>}
                                {atributes.find(atribute=>atribute.name === "services").show && <b><div>Usługi</div></b>}
                            </li>
                            {mappedEvents}
                        </ul>
                    </div>
                </section>
                <ListNavigation  pageIndicator={pageIndicator} setPageIndicator={setPageIndicator} itemsPerPage={eventsPerPage} setItemsPerPage={setEventsPerPage} items={allEvents}/>
            </div>}
        </div>
    )
}

export default EventsHistory
