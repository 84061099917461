import { useState } from "react";
import { useCookies } from "react-cookie";

const EditVehicle = (props) => {
    const {vehicleToEdit, version, setVehicleEditMode} = props
    const [cookies, setCookie] = useCookies();
    const [brand, setBrand] = useState(vehicleToEdit.brand)
    const [model, setModel] = useState(vehicleToEdit.model)
    const [plateNumber, setPlateNumber] = useState(vehicleToEdit.plateNumber)
    const [vin, setVin] = useState(vehicleToEdit.vin || '11111111111111111')
    const editedVehicle = {
        "brand": brand,
        "model": model,
        "plateNumber": plateNumber,
        "vin": vin
    }
    const saveVehicle = (e) => {
        if( vin.length===17 &&
            !vin.includes(" ") &&
            !vin.includes("O") &&
            !vin.includes("Q") &&
            !vin.includes("I")){
            fetch('https://'+ version + '.soft99.eu/calendar-vehicles/'+vehicleToEdit.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ` + cookies.JWTToken,
                },
                body: JSON.stringify(editedVehicle)
            }).then(response=>{
                
            })
        }
        else{
            e.preventDefault();
            alert("Kod VIN jest niepoprawny. Kod VIN musi składać się z 17. znaków i nie może zawierać spacji oraz liter: 'I', 'Q' oraz 'O'. Dziewiąty znak kodu VIN musi być cyfrą.")
        }
    }
    return(
        <div className="edit-vehicle container">
            <form className="content">
                <h4>Wprowadź nowe dane pojazdu:</h4>
                <input 
                    placeholder="marka"
                    className="form-control"
                    value={brand}
                    onChange={(e)=>setBrand(e.target.value)}></input>
                <input 
                    placeholder="model"
                    className="form-control"
                    value={model}
                    onChange={(e)=>setModel(e.target.value)}></input>
                <input 
                    placeholder="numer rejestracyjny"
                    className="form-control"
                    value={plateNumber}
                    onChange={(e)=>setPlateNumber(e.target.value)}></input>
                <input 
                    placeholder="VIN"
                    className="form-control"
                    value={vin}
                    onChange={(e)=>setVin(e.target.value)}></input>
                <div className="buttons">
                    <button
                        className="btn btn-danger"
                        onClick={()=>setVehicleEditMode(false)}>Anuluj</button>
                    <button
                        className="btn btn-primary"
                        onClick={(e)=>saveVehicle(e)}>Zapisz</button>
                </div>
            </form>
        </div>
    )
}

export default EditVehicle